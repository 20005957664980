import { Activity } from '../../core/interfaces/activity'
import { TextToSpeech } from '@capacitor-community/text-to-speech'
import { useState } from 'react'
import useLanguage from '../settings/useLanguage'

const speak = async (language: string, text: string) => {
  await TextToSpeech.speak({
    text: text,
    lang: language,
    rate: 1.0,
    pitch: 1.0,
    volume: 1.0,
    category: 'ambient',
  })
}

const useAudio = (currentActivity: Activity): void => {
  const [index, setIndex] = useState(0)
  const { language } = useLanguage()

  if (currentActivity && currentActivity.hasSpeech) {
    speak(language, currentActivity.title)
    const timerId = setTimeout(() => {
      setIndex(index + 1)
      speak(language, currentActivity.title)
      if (index > +currentActivity.speechMaxRepeat) {
        clearTimeout(timerId)
      }
    }, +currentActivity.speechInterval)
  }
}

export default useAudio

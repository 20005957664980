import React from 'react'
import useColor from '../../../hooks/settings/useColor'
import { Color } from '../../../store/settings/types'
import './Notch.css'

export enum NotchType {
  Large = 'large',
  Small = 'small',
}

export interface NotchInterface {
  /** The type of the notch */
  type: NotchType
  /** the angle for the notch */
  notchAngle: number
  /** The hour for the notch */
  hour?: number
}

const Notch: React.FC<NotchInterface> = (props: NotchInterface) => {
  const { type, notchAngle, hour } = props
  const { color: textColor } = { ...useColor(Color.Text) }
  const notchStyle = {
    transform: `rotate(${notchAngle}deg)`,
    '--notch-color': { color: textColor }.color,
  }

  const hourStyle = {
    transform: `rotate(-${notchAngle}deg)`,
  }

  return (
    <div className={`clock-notch clock-notch__${type}`} style={notchStyle}>
      {type === NotchType.Large && (
        <div className="clock-notch__hour" style={hourStyle}>
          <span style={{ color: textColor }}>{hour}</span>
        </div>
      )}
    </div>
  )
}

export default Notch

import React, { useEffect, useState } from 'react'
import { Participant, Track } from 'twilio-video'
import VideoTrack from './VideoTrack'

interface Props {
  localParticipant: boolean
  participant: Participant
}

const VideoParticipant = ({ localParticipant, participant }: Props) => {
  const [tracks, setTracks] = useState<Track[]>([])

  useEffect(() => {
    const existingPublications = Array.from(participant.tracks.values())

    //eslint-disable-next-line
    const existingTracks = existingPublications.map((publication: any) => {
      return publication.track
    })

    const nonNullTracks = existingTracks.filter((track) => track !== null)
    setTracks(nonNullTracks)

    if (!localParticipant) {
      participant.on('trackSubscribed', (track) => addTrack(track))
    }
    return () => {
      if (!localParticipant) {
        participant.off('trackSubscribed', (track) => addTrack(track))
      }
    }
  }, []) // eslint-disable-line

  const addTrack = (track: Track) => {
    setTracks((prevTracks) => [...prevTracks, track])
  }

  if (localParticipant) {
    return (
      <div
        className="participant--local w-2/5 h-1/6 absolute top-5 right-5 border-solid border-2 border-dayclock-green z-10 h-auto"
        id={participant.identity}
      >
        {tracks.map((track) => {
          return <VideoTrack key={track.name} track={track} />
        })}
      </div>
    )
  }

  return (
    <div className="participant--remote h-full w-full absolute top-0 left-0 z-0" id={participant.identity}>
      {tracks.map((track) => {
        return <VideoTrack key={track.name} track={track} />
      })}
    </div>
  )
}

export default VideoParticipant

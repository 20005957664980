import { AxiosPromise } from 'axios'
import { LoginCredential } from '../../core/interfaces/settings'
import request from './BaseApi'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const login = (data: LoginCredential): AxiosPromise<any> => {
  const header = {
    Authorization: 'Basic ' + btoa(`${data.username}:${data.password}`),
  }
  return request({
    url: `auth/login/`,
    method: 'POST',
    headers: header,
    data: data,
  })
}

import { combineReducers, configureStore } from '@reduxjs/toolkit'
import settings from './settings/reducer'
import user from './user/userSlice'
import weather from './settings/weatherSlice'
import allActivities from './activities/activitiesSlice'
import album from './album/albumSlice'
import photo from './photo/photoSlice'

const rootReducer = combineReducers({
  user,
  allActivities,
  settings,
  weather,
  album,
  photo,
})

export const store = configureStore({
  reducer: rootReducer,
})

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch

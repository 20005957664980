import React from 'react'
import clsx from 'clsx'

import './Globe.css'

export interface GlobeProps {
  className?: string
}

const Globe: React.FC<GlobeProps> = (props: GlobeProps) => {
  const { className } = props

  return (
    <div className={clsx('setup-globe', className)}>
      <div className="globe" />
      <div className="cloud1" />
      <div className="cloud2" />
      <div className="cloud3" />
      <div className="cloud4" />
      <div className="cloud5" />
    </div>
  )
}

export default Globe

import clsx from 'clsx'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { VERSION } from '../../../core/const'
import HeaderIcon from './HeaderIcon'
import './SetupHeader.css'

interface Props {
  hideSteps?: boolean
}

const SetupHeader: React.FC<Props> = (props: Props) => {
  const location = useLocation()
  const { t } = useTranslation()

  return (
    <>
      <div className="flex flow-row items-center setup-header">
        <div className="flex-1 ml-10 dc7:ml-5">
          <img className="h-10" src="/assets/images/logo-text.svg" alt="logo" />
        </div>
        <div className={clsx('flex-initial w-96', { hidden: props.hideSteps })}>
          <div className="flex flex-row justify-around setup-header__icons">
            <HeaderIcon
              to="/setup/language"
              label="1"
              active={location.pathname === '/setup/language' || location.pathname === '/'}
            />
            <HeaderIcon to="/setup/wifi" label="2" active={location.pathname === '/setup/wifi'} />
            <HeaderIcon to="/setup/account" label="3" active={location.pathname === '/setup/account'} />
          </div>
        </div>
        <div className="flex-1 text-right mr-10 dc7:mr-5 font-body">
          {t('setup.version')} {VERSION} - p:{window.devicePixelRatio} w:{window.outerWidth} h:{window.outerHeight}
        </div>
      </div>
    </>
  )
}

export default SetupHeader

enum SettingType {
  General = 'general',
  Themes = 'themes',
  Weather = 'weather',
  Extra = 'extra',
  DayParts = 'dayparts',
  Clock = 'clock',
  Help = 'help',
}

export default SettingType

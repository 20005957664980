import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import 'moment/locale/nl'
import 'moment/locale/fr'
import 'moment/locale/de'
import 'moment/locale/da'
import 'moment/locale/es'

import translationEN from './i18n/en/translation.json'
import translationNL from './i18n/nl/translation.json'
import translationFR from './i18n/fr/translation.json'
import translationDE from './i18n/de/translation.json'
import translationDA from './i18n/da/translation.json'
import translationES from './i18n/es/translation.json'

const resources = {
  nl: {
    translation: translationNL,
  },
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
  de: {
    translation: translationDE,
  },
  da: {
    translation: translationDA,
  },
  es: {
    translation: translationES,
  },
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'nl',
  interpolation: {
    escapeValue: false,
  },
})

export default i18n

import React, { useEffect } from 'react'
import ReactPlayer from 'react-player'
import useAnalytics from '../../hooks/analytics/useAnalytics'

export interface YoutubeProps {
  youtubeId: string
}

const Youtube: React.FC<YoutubeProps> = (props: YoutubeProps) => {
  const { youtubeId } = props

  const width = window.innerWidth
  const height = window.innerHeight - 20

  const { trackView } = useAnalytics()

  useEffect(() => {
    trackView('Youtube')
  })

  return (
    <div className="mt-20">
      <ReactPlayer url={youtubeId} playing={true} loop={true} width={`${width}px`} height={`${height}px`} />
    </div>
  )
}

export default Youtube

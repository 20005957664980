import React from 'react'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import useClock from '../../../hooks/settings/useClock'
import { IonCol, IonGrid, IonRow } from '@ionic/react'
import './PureDigitalClock.css'
import DayPart from '../../daypart/DayPart'

export interface PureDigitalClockProps {
  /** the current date */
  date: Date
  dateFormat: string
  timeFormat: string
  color?: string
  backgroundColor?: string
}

export const PureDigitalClock: React.FC<PureDigitalClockProps> = (props: PureDigitalClockProps) => {
  const { date, timeFormat, dateFormat, backgroundColor, color } = props
  const { i18n } = useTranslation()
  const { showDayPart } = useClock()

  return (
    <div id="container" className="ion-text-center my-auto" style={{ backgroundColor: backgroundColor }}>
      <IonGrid className="mt-8">
        <IonRow justify-content-center align-items-center class="centeredElement">
          <IonCol>
            <div>
              <div style={{ color: color }}>
                <Moment locale={i18n.language} format="dddd" className="day">
                  {date}
                </Moment>
              </div>

              <div className="mt-8 dc7:mt-0 text-7xl dc7:text-6xl" style={{ color: color }}>
                <Moment locale={i18n.language} format={dateFormat}>
                  {date}
                </Moment>
              </div>
              <div className="mt-14 dc7:mt-6 text-7xl dc7:text-6xl" style={{ color: color }}>
                <Moment locale={i18n.language} format={timeFormat} className="mr-16">
                  {date}
                </Moment>
                {showDayPart && <DayPart />}
              </div>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  )
}

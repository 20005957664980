import React from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import SetupFlag from '../flag/SetupFlag'
import { Language } from '../../../store/settings/types'
import useLanguage from '../../../hooks/settings/useLanguage'

export interface LanguageSelectorProps {
  className?: string
}

const LanguageSelector: React.FC<LanguageSelectorProps> = (props: LanguageSelectorProps) => {
  const { className } = props
  const { t } = useTranslation()
  const { language } = useLanguage()

  return (
    <div className={clsx('mt-24 ml-12 w-2/4', className)}>
      <div className="text-4xl font-sans mb-10">{t('setup.language.title')}</div>
      <div className="flex flex-wrap -ml-9">
        {Object.values(Language).map((keyName, keyIndex) => {
          return <SetupFlag key={keyIndex} language={keyName} selected={language === keyName} />
        })}
      </div>
    </div>
  )
}

export default LanguageSelector

import React from 'react'
import { IonItem, IonLabel, IonToggle } from '@ionic/react'

export interface SettingsToggleItemProps {
  label: string
  sublabel?: string
  checked?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: any
}

const SettingsToggleItem: React.FC<SettingsToggleItemProps> = (props: SettingsToggleItemProps) => {
  const { label, checked, onChange, sublabel } = props

  return (
    <IonItem className="dc7:text-sm">
      <IonLabel>
        {label}
        {sublabel && (
          <span className="italic text-gray-500">
            <br />
            {sublabel}
          </span>
        )}
      </IonLabel>
      <IonToggle onIonChange={(event) => onChange(event.detail.checked)} checked={checked}></IonToggle>
    </IonItem>
  )
}

SettingsToggleItem.defaultProps = {
  checked: false,
}

export default SettingsToggleItem

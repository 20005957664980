import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AlbumState, getAlbum } from '../../store/album/albumSlice'
import { Photo } from '../../store/settings/types'
import { RootState } from '../../store/store'
import useLogin from '../settings/useLogin'
interface AlbumInterface {
  albumId: string
  duration: number
  photos: Photo[]
}

const useAlbum = (albumId: string): AlbumInterface => {
  const dispatch = useDispatch()
  const { duration, photos } = useSelector((state: RootState) => state.album) as AlbumState

  const { token } = useLogin()

  useEffect(() => {
    dispatch(getAlbum({ token, albumId }))
  }, [dispatch, albumId, token])

  return {
    albumId,
    duration,
    photos,
  }
}

export default useAlbum

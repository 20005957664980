/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosPromise } from 'axios'

import { WeatherRequest } from '../../core/interfaces/request'
import request from './BaseApi'
import * as i18nIsoCountries from 'i18n-iso-countries'
// eslint-disable-next-line
i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'))

export const getWeather = ({ country, city, unit, language }: WeatherRequest): AxiosPromise<any> => {
  const countryName = i18nIsoCountries.getName(country, 'en')

  return request({
    url: `weather/now/${city}/${countryName}/${unit}/${language}`,
    method: 'GET',
  })
}

import React from 'react'
import useClockType from '../../hooks/settings/clockType'
import { ClockTypeEnum } from '../../store/settings/types'
import Youtube from '../youtube/PureYoutube'
import { ClockWeatherComponent } from './ClockWeatherComponent'
import { ClockPhotoComponent } from './ClockPhotoComponent'
import useUpdateWeatherForecast from '../../hooks/settings/useUpdateWeatherForecast'
import Album from '../album/Album'
import useCurrentActivity from '../../hooks/activities/useCurrentActivity'
import Weather from '../weather/Weather'
import ActivityBar from '../activity/ActivityBar'
import useActivityPhoto from '../../hooks/activities/useActivityPhoto'

export interface ClockFaceProps {
  hidden?: boolean
}

export interface CalendarComponentProps {
  type: ClockTypeEnum
  size: number
}

const ClockFace: React.FC = () => {
  useUpdateWeatherForecast()
  const { type } = useClockType()
  const clockSize = 550
  const { currentActivity } = useCurrentActivity()
  const { photo } = useActivityPhoto(currentActivity?.key)

  const isPhotoVisible = photo && currentActivity && photo.pictureUrl !== null && photo.pictureData !== null

  const isAlbumVisible = currentActivity && currentActivity.album !== null

  const isYouTubeVisible = currentActivity && currentActivity.youtubeId !== null

  if (isYouTubeVisible) {
    return <Youtube youtubeId={currentActivity.youtubeId} />
  }

  if (isAlbumVisible) {
    return <Album albumId={currentActivity.album} />
  }

  if (isPhotoVisible) {
    return (
      <>
        <ActivityBar />
        <Weather />
        <div className="flex w-full justify-center mt-32" style={{ height: 'calc(100% - 8rem)' }}>
          <ClockPhotoComponent
            type={type}
            clockSize={clockSize}
            pictureData={photo?.pictureData}
            pictureUrl={photo?.pictureUrl}
          />
        </div>
      </>
    )
  }

  return (
    <>
      <ActivityBar />
      <Weather />
      <div className="flex w-full justify-center mt-32" style={{ height: 'calc(100% - 8rem)' }}>
        <ClockWeatherComponent type={type} clockSize={clockSize} />
      </div>
    </>
  )
}

export default ClockFace

const invertColor = (hex: string): string => {
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1)
  }

  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
  }

  if (hex.length !== 6) {
    return '#' + hex
  }

  const red = parseInt(hex.slice(0, 2), 16),
    green = parseInt(hex.slice(2, 4), 16),
    blue = parseInt(hex.slice(4, 6), 16)

  return red * 0.299 + green * 0.587 + blue * 0.114 > 186 ? '#000000' : '#ffffff'
}

export default invertColor

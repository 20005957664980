export const formatColor = (color: string): string => {
  if (color === undefined || color === null) {
    return '#fff'
  }

  return color.includes('#') ? color : `#${color}`
}

export const formatBackgroundColor = (color: string): string => {
  if (color === undefined || color === null) {
    return '#ff0000'
  }

  return color.includes('#') ? color : `#${color}`
}

export const truncateSentence = (text: string, length: number): string => {
  if (text && text.length > length) {
    const sentence = text.substring(0, length)
    const index = sentence.lastIndexOf(' ')
    return sentence.substring(0, index) + '...'
  }
  return text
}

export const capitalizeFirst = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

import { AxiosPromise } from 'axios'
import { URL_SERVER } from '../../core/const'
import request from './BaseApi'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getActivities = (username: string, month: string): AxiosPromise<any> => {
  return request({
    url: `${URL_SERVER}api/v2/activities/user/${username}/month/${month}`,
    method: 'GET',
  })
}

import React from 'react'
import { IonContent, IonPage } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { useNetwork } from '../../hooks/network/useNetwork'
import SetupButton from '../../components/setup/button/SetupButton'
import SetupHeader from '../../components/setup/header/SetupHeader'
import WifiSetup from '../../components/setup/wifi-setup/WifiSetup'

import './SetupWifi.css'

const SetupWifi: React.FC = () => {
  const connected = useNetwork()
  const { t } = useTranslation()

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="bg-blue-200 w-full h-full p-2 pt-10">
          <SetupHeader />
          <div className="rounded-md bg-white m-10 h-5/6 pt-10 pl-10 relative overflow-hidden dc7:m-5 dc7:pl-5 dc7:pt-5">
            <WifiSetup />
            <SetupButton className="setup-button-prev__position" to="/setup/language" isBack={true}>
              {t('buttons.prev')}
            </SetupButton>
            <SetupButton className="setup-button-next__position" to="/setup/account" disabled={!connected}>
              {t('buttons.next')}
            </SetupButton>
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default SetupWifi

import React, { useEffect } from 'react'
import useAnalytics from '../../../hooks/analytics/useAnalytics'
import { PureAnalogClock } from './PureAnalogClock'
import { useTime } from './useTime'

export interface AnalogClockProps {
  size: number
  digital?: boolean
}

export const AnalogClock: React.FC<AnalogClockProps> = (props: AnalogClockProps) => {
  const { size } = props
  const { trackView } = useAnalytics()

  const time = useTime()

  useEffect(() => {
    trackView('AnalogClock')
  })

  return <PureAnalogClock size={size} time={time} />
}

import React from 'react'
import useLanguage from '../../hooks/settings/useLanguage'
import { Language, Temperature } from '../../store/settings/types'
import { Weather as WeatherType } from './../../core/interfaces/settings'
import './WeatherBar.css'

export interface Weather {
  weather: WeatherType
  temperature: Temperature
}

const getDescription = (language: string, weather: WeatherType): string => {
  let description
  switch (language) {
    case Language.dutch: {
      description = weather.current.weather_descriptions_nl ? weather.current.weather_descriptions_nl[0] : ''
      break
    }
    case Language.german: {
      description = weather.current.weather_descriptions_de ? weather.current.weather_descriptions_de[0] : ''
      break
    }
    case Language.french: {
      description = weather.current.weather_descriptions_fr ? weather.current.weather_descriptions_fr[0] : ''
      break
    }
    default: {
      description = weather.current.weather_descriptions ? weather.current.weather_descriptions[0] : ''
    }
  }
  return description
}

const WeatherBar: React.FC<Weather> = ({ weather, temperature }: Weather) => {
  const { language } = useLanguage()
  const icon = weather.current.weather_icons[0] ?? ''
  const description = getDescription(language, weather)

  return (
    icon && (
      <div className="flex justify-end items-center text-4xl">
        <div>
          {weather.current.temperature} °{temperature === Temperature.Celsius ? 'C' : 'F'}
        </div>
        <div className="mx-5">{description}</div>
        <div>
          <img src={icon} style={{ height: '80px' }} alt="weather" />
        </div>
      </div>
    )
  )
}

export default WeatherBar

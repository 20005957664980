import React, { useEffect, useRef, useState } from 'react'
import useAlbum from '../../hooks/album/useAlbum'
import useAnalytics from '../../hooks/analytics/useAnalytics'

interface props {
  albumId: string
}

const Album: React.FC<props> = (props: props) => {
  const { albumId } = props

  const { duration, photos } = useAlbum(albumId)
  const { trackView } = useAnalytics()
  const timer = useRef(null)
  const [currentImageIndex, setCurrentImageIndex] = useState(0)

  const fadeToNextImage = () => {
    setCurrentImageIndex((prevState) => {
      if (prevState >= photos.length - 1) {
        return 0
      }
      return prevState + 1
    })
  }

  useEffect(() => {
    trackView('Album')
    timer.current = setInterval(() => fadeToNextImage(), duration * 1000)
    return () => {
      clearInterval(timer.current)
    }
  })

  return (
    <div className="h-full w-full relative">
      {photos?.map((photo, index) => {
        const opacity = currentImageIndex === index ? 100 : 0

        return (
          <div
            className={`h-full w-full absolute bg-cover bg-center bg-no-repeat transition-opacity duration-300 opacity-${opacity}`}
            style={{ backgroundImage: `url("${photo}")` }}
            key={`image-galley-slide-${index}`}
          >
            &nbsp;
          </div>
        )
      })}
    </div>
  )
}

export default Album

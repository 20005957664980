import React from 'react'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import './DayViewHeader.css'
import useDateInterval from '../../hooks/interval/useDateInterval'
import DayPart from '../daypart/DayPart'

const DayHeader: React.FC = () => {
  const { i18n } = useTranslation()
  const { date } = useDateInterval(60)

  return (
    <>
      <div className="flex">
        <div className="flex-1 font-semibold dayheader text-left text-3xl pl-5">
          <Moment date={date} format={'D MMMM YYYY'} locale={i18n.language} className="mt-20 dc7:text-2xl text-3xl" />
        </div>
        <div className="font-semibold flex-1 text-right pr-5">
          <Moment date={date} format="dddd" locale={i18n.language} className="date_day dc7:text-2xl text-3xl" />
        </div>
        <div className="flex-1 font-semibold text-left pl-5 dc7:text-2xl text-3xl">
          <DayPart />
        </div>

        <div className="flex-1 font-semibold dayheader text-right pr-5 dc7:text-2xl text-3xl">
          <Moment date={date} format={'HH:mm'} />
        </div>
      </div>
    </>
  )
}

export default DayHeader

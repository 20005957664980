import React from 'react'
import SettingsHeader from '../settings-header/SettingsHeader'

const SettingsTheme: React.FC = () => {
  return (
    <>
      <SettingsHeader title="Theme" color="iconTheme" />
    </>
  )
}

export default SettingsTheme

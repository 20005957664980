import React from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { Language } from '../../../store/settings/types'
import './SetupFlag.css'
import useLanguage from '../../../hooks/settings/useLanguage'

export interface FlagProps {
  language: Language
  selected?: boolean
}

const SetupFlag: React.FC<FlagProps> = (props: FlagProps) => {
  const { language, selected } = props
  const { t } = useTranslation()
  const { handleChange } = useLanguage()

  const handleOnClick = () => {
    handleChange(language)
  }

  return (
    <div className={clsx('flag', { 'flag--selected': selected })} onClick={handleOnClick}>
      <div className="flag-image-container">
        <div className="flag-image-wrapper">
          <img className="flag-image" src={`assets/images/flags/${language}.svg`} alt={t(`languages.${language}`)} />
        </div>
      </div>
      <div className="flag-label">{t(`languages.${language}`)}</div>
    </div>
  )
}

SetupFlag.defaultProps = {
  selected: false,
}

export default SetupFlag

import React from 'react'
import { formatColor } from '../../core/helper/string.helper'

export interface ActivityTextProps {
  text: string
  color: string
}

const ActivityText: React.FC<ActivityTextProps> = (props: ActivityTextProps) => {
  const { text, color } = props

  const style = {
    color: formatColor(color),
  }

  const size = calculateFontSize(text)
  const lines = getLines(text)

  const component =
    lines[1] === undefined ? (
      <span style={{ fontSize: size }}>{lines[0].substring(0, 100)}</span>
    ) : (
      <>
        <span style={{ fontSize: size }}>{lines[0]}</span>
        <br />
        <span className="overflow-hidden" style={{ fontSize: size }}>
          {lines[1].substring(0, 50)}
        </span>
      </>
    )

  return <span style={style}>{component}</span>
}

const calculateFontSize = (text: string): string => {
  return text.length > 50 || text.includes('|') ? '0.8em' : '1em'
}

const getLines = (text: string): [string, string | undefined] => {
  let line1 = text
  let line2 = undefined

  if (text.includes('|')) {
    line1 = text.split('|')[0]
    line2 = text.split('|')[1]
  }

  return [line1, line2]
}

export default ActivityText

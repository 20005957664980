import { isPlatform } from '@ionic/react'
import { Storage } from '@capacitor/storage'
import { desktopStorage } from './local-storage'
import { SettingsStateI } from '../store/settings/types'
import { UserStateI } from '../store/user/userSlice'

const GLOBAL_SETTING_STATE = 'global_settings_state'
const GLOBAL_USER_STATE = 'global_user_state'

export interface StoredStateI {
  settings: SettingsStateI
  user: UserStateI
}

export const storeSettingsState = (value: SettingsStateI): void => {
  if (isPlatform('desktop')) {
    desktopStorage.set({
      key: GLOBAL_SETTING_STATE,
      value: JSON.stringify(value),
    })
  } else {
    Storage.set({ key: GLOBAL_SETTING_STATE, value: JSON.stringify(value) })
  }
}

export const storeUserState = (value: UserStateI): void => {
  if (isPlatform('desktop')) {
    desktopStorage.set({
      key: GLOBAL_USER_STATE,
      value: JSON.stringify(value),
    })
  } else {
    Storage.set({ key: GLOBAL_USER_STATE, value: JSON.stringify(value) })
  }
}

const loadDesktopStoredState = async (): Promise<StoredStateI> => {
  const storedSettingsState = await desktopStorage.get(GLOBAL_SETTING_STATE)
  const settings = JSON.parse(storedSettingsState)

  const storedUserState = await desktopStorage.get(GLOBAL_USER_STATE)
  const user = JSON.parse(storedUserState)

  return { settings: settings, user: user }
}

const loadDeviceStoredState = async (): Promise<StoredStateI> => {
  const storedSettingsState = await Storage.get({ key: GLOBAL_SETTING_STATE })
  const settings = JSON.parse(storedSettingsState.value)

  const storedUserState = await Storage.get({ key: GLOBAL_USER_STATE })
  const user = JSON.parse(storedUserState.value)

  return { settings: settings, user: user }
}

export const loadStoredState = async (): Promise<StoredStateI> => {
  if (isPlatform('desktop')) {
    return await loadDesktopStoredState()
  } else {
    return await loadDeviceStoredState()
  }
}

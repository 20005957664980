import { Network } from '@capacitor/network'
import { useEffect, useState } from 'react'

export const useNetwork = (): boolean => {
  const [connected, isConnected] = useState(false)

  useEffect(() => {
    async function checkConnection() {
      const status = await Network.getStatus()
      isConnected(status.connected)
    }

    checkConnection()

    Network.addListener('networkStatusChange', (status) => {
      isConnected(status.connected)
    })

    return () => {
      Network.removeAllListeners()
    }
  }, [])

  return connected
}

import { IonIcon } from '@ionic/react'
import './SettingsHeader.css'
import React from 'react'
import { settings, colorFill, sunny, ellipsisHorizontalCircle, contrast, time } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'

interface Props {
  title: string
  color: string
}

const SettingsHeader: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  return (
    <div className="flex pl-4 mb-3">
      {props.title === 'General' ? <IonIcon icon={settings} className={`text-4xl mr-4 ${props.color}`}></IonIcon> : ''}
      {props.title === 'Theme' ? <IonIcon icon={colorFill} className={`text-4xl mr-4 ${props.color}`}></IonIcon> : ''}
      {props.title === 'Weather' ? <IonIcon icon={sunny} className={`text-4xl mr-4 ${props.color}`}></IonIcon> : ''}
      {props.title === 'Extra' ? (
        <IonIcon icon={ellipsisHorizontalCircle} className={`text-4xl mr-4 ${props.color}`}></IonIcon>
      ) : (
        ''
      )}
      {props.title === 'Dayparts' ? <IonIcon icon={contrast} className={`text-4xl mr-4 ${props.color}`}></IonIcon> : ''}
      {props.title === 'Clock' ? <IonIcon icon={time} className={`text-4xl mr-4 ${props.color}`}></IonIcon> : ''}

      <div className="text-3xl font-extrabold">{t(props.title)}</div>
    </div>
  )
}

export default SettingsHeader

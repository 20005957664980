import React from 'react'
import { ClockTypeEnum } from '../../store/settings/types'
import { ClockComponent } from './ClockComponent'
import { DayComponent } from './DayComponent'

export interface ClockWeatherComponentProps {
  type: ClockTypeEnum
  clockSize: number
}

export const ClockWeatherComponent: React.FC<ClockWeatherComponentProps> = (props: ClockWeatherComponentProps) => {
  const { type, clockSize } = props
  return (
    <>
      <div className="flex-1 p-5 ml-10">
        <ClockComponent type={type} size={clockSize} />
      </div>
      {(type === ClockTypeEnum.Analog || type === ClockTypeEnum.DigitalAndAnalog) && <DayComponent />}
    </>
  )
}

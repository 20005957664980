import React from 'react'
import { IonButton, IonIcon } from '@ionic/react'
import { call } from 'ionicons/icons'
import './Lobby.css'

interface Props {
  startCall: () => void
  calling: boolean
}

const Lobby = ({ startCall, calling }: Props) => {
  return (
    <>
      <div className={`h-full flex justify-center items-center`}>
        <IonButton onClick={startCall} className={`pulse w-48 h-48 ${calling && 'animate'}`} fill="clear">
          <IonIcon icon={call} className="w-32 h-32 text-white"></IonIcon>
        </IonButton>
      </div>
    </>
  )
}

export default Lobby

import React, { useState } from 'react'
import SettingsClock from '../../components/settings/settings-clock/SettingsClock'
import SettingsDayparts from '../../components/settings/settings-dayparts/SettingsDayparts'
import SettingsGeneral from '../../components/settings/settings-general/SettingsGeneral'
import Help from '../../components/settings/settings-help/Help'
import SettingsTheme from '../../components/settings/settings-theme/SettingsTheme'
import SettingsWeather from '../../components/settings/settings-weather/SettingsWeather'
import SettingsWrapper from '../../components/settings/SettingsWrapper'
import settingsMenuContext from '../../core/context/settingsMenuContext'
import SettingType from '../../core/enums/SettingsType'

const getSettingsComponent = (setting: SettingType) => {
  switch (setting) {
    case SettingType.General:
      return <SettingsGeneral />
      break
    case SettingType.Themes:
      return <SettingsTheme />
      break
    case SettingType.Clock:
      return <SettingsClock />
      break
    case SettingType.DayParts:
      return <SettingsDayparts />
      break
    case SettingType.Weather:
      return <SettingsWeather />
      break
    case SettingType.Help:
      return <Help />
      break
    default:
      return <SettingsGeneral />
      break
  }
}

const SettingsPage: React.FC = () => {
  const [selectedSetting, setSelectedSetting] = useState(SettingType.General)

  return (
    <settingsMenuContext.Provider value={{ selectedSetting, setSelectedSetting }}>
      <SettingsWrapper>{getSettingsComponent(selectedSetting)}</SettingsWrapper>
    </settingsMenuContext.Provider>
  )
}
export default SettingsPage

import {
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonRadio,
  IonRadioGroup,
  IonSelect,
  IonSelectOption,
} from '@ionic/react'
import React from 'react'

import { useTranslation } from 'react-i18next'
import SettingsHeader from '../settings-header/SettingsHeader'
import useWeather from '../../../hooks/settings/useWeather'
import useLanguage from '../../../hooks/settings/useLanguage'
import { sortedCountries } from '../../../core/helper/countries.helper'

const SettingsWeather: React.FC = () => {
  const { t } = useTranslation()
  const { language } = useLanguage()

  // TODO : set default country by location
  const { country, city, temperature, setCountry, setCity, setTemperature } = useWeather()

  const changeCountry = (code: string) => {
    setCountry(code)
  }

  const countries = sortedCountries(language)

  return (
    <>
      <SettingsHeader title="Weather" color="iconWeather" />

      <IonList className="w-full">
        <IonItem>
          <IonLabel className="font-extrabold">{t('Country')}</IonLabel>
          <IonItem>
            <IonSelect value={country} cancelText={t('Cancel')} onIonChange={(e) => changeCountry(e.detail.value)}>
              {countries.map((country, index) => {
                return (
                  <IonSelectOption value={country.key} key={index}>
                    {country.value}
                  </IonSelectOption>
                )
              })}
            </IonSelect>
          </IonItem>
        </IonItem>
        <IonItem>
          <IonLabel className="font-extrabold">{t('City')}</IonLabel>
          <IonInput className="text-right" value={city} onIonChange={(e) => setCity(e.detail.value)} />
        </IonItem>

        <IonListHeader className="mt-5 text-lg font-extrabold">{t('Temperature')}</IonListHeader>

        <IonRadioGroup value={temperature} onIonChange={(e) => setTemperature(e.detail.value)}>
          <IonItem>
            <IonLabel>{t('settings.menu.weather.m')}</IonLabel>
            <IonRadio slot="end" value="m" />
          </IonItem>
          <IonItem>
            <IonLabel>{t('settings.menu.weather.f')}</IonLabel>
            <IonRadio slot="end" value="f" />
          </IonItem>
        </IonRadioGroup>
      </IonList>
    </>
  )
}

export default SettingsWeather

import React, { useEffect, useMemo } from 'react'
import DayActivity from './DayActivity'
import DayViewHeader from './DayViewHeader'
import useActivities from '../../hooks/activities/useActivities'
import useDateInterval from '../../hooks/interval/useDateInterval'
import activitiesHelper from '../../core/helper/activities.helper'
import useAnalytics from '../../hooks/analytics/useAnalytics'

const DayView: React.FC = () => {
  const { activities } = useActivities()
  const { date } = useDateInterval(60)
  const activitiesPerDay = useMemo(
    () => activitiesHelper.findActivitiesByDayAndTime(date, activities),
    [activities, date],
  )
  const { trackView } = useAnalytics()

  useEffect(() => {
    trackView('Day')
  })

  return (
    <>
      <div className="bg-blue-200 w-full h-full p-2 pt-10">
        <DayViewHeader />
        <div className="rounded-md bg-white m-5 h-5/6 pt-5 relative">
          {[...activitiesPerDay].map((activity, i) => (
            <DayActivity key={i} activity={activity} />
          ))}
        </div>
      </div>
    </>
  )
}

export default DayView

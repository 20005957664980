import React, { useState, useEffect } from 'react'
import useAnalytics from '../../../hooks/analytics/useAnalytics'
import useClock from '../../../hooks/settings/useClock'
import useColor from '../../../hooks/settings/useColor'
import { Color } from '../../../store/settings/types'
import { PureDigitalClock } from './PureDigitalClock'

const DigitalClock: React.FC = () => {
  const [date, setDate] = useState(new Date())
  const [tFormat, setTFormat] = useState('')
  const { dateFormat, timeFormat } = useClock()
  const { color: textColor } = { ...useColor(Color.Text) }
  const { color: backgroundColor } = useColor(Color.Background)
  const { trackView } = useAnalytics()

  useEffect(() => {
    trackView('DigitalClock')
    setTFormat(timeFormat === 'H' ? 'H:mm' : 'h:mm A')
    const timerId = setInterval(() => tick(), 1000)
    return function cleanup() {
      clearInterval(timerId)
    }
  }, [dateFormat, timeFormat, trackView])

  function tick() {
    setDate(new Date())
  }

  return (
    <PureDigitalClock
      date={date}
      dateFormat={dateFormat}
      timeFormat={tFormat}
      color={textColor}
      backgroundColor={backgroundColor}
    />
  )
}

DigitalClock.defaultProps = {
  locale: 'nl',
}

export default DigitalClock

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { WeatherRequest } from '../../core/interfaces/request'
import { getWeather as getWeatherService } from '../../services/api/WeatherApi'
import { GET_WEATHER } from './types'

interface WeatherState {
  isError: boolean
  weather: {
    current: {
      weather_icons: Array<string>
      temperature: string
      weather_descriptions: Array<string>
      weather_descriptions_nl: Array<string>
      weather_descriptions_de: Array<string>
      weather_descriptions_fr: Array<string>
    }
  }
}

const initialState: WeatherState = {
  isError: false,
  weather: {
    current: {
      weather_icons: [],
      temperature: null,
      weather_descriptions: [],
      weather_descriptions_nl: [],
      weather_descriptions_de: [],
      weather_descriptions_fr: [],
    },
  },
}

export const getWeather = createAsyncThunk(GET_WEATHER, async (request: WeatherRequest) => {
  const { data } = await getWeatherService(request)
  return data
})

export const weatherSlice = createSlice({
  name: 'weather',
  initialState,
  reducers: {},
  extraReducers: {
    [getWeather.fulfilled.toString()]: (state, { payload }) => {
      state.isError = false
      state.weather = payload
    },
    [getWeather.rejected.toString()]: (state) => {
      state.isError = true
    },
  },
})

export default weatherSlice.reducer

import React, { useEffect, useState } from 'react'
import { formatBackgroundColor } from '../../core/helper/string.helper'
import useAudio from '../../hooks/activities/useAudio'
import useSpeech from '../../hooks/activities/useSpeech'
import ActivityText from './ActivityText'
import classnames from 'classnames'
import useCurrentActivity from '../../hooks/activities/useCurrentActivity'

const ActivityBar: React.FC = () => {
  const [barStyle, setBarStyle] = useState({})
  const [barClasses, setBarClasses] = useState('')
  const { currentActivity } = useCurrentActivity()

  useAudio({
    interval: 5,
    repeat: 5,
    volume: 1,
    audioName: '',
    enable: currentActivity != null ? currentActivity.hasSound : false,
  })

  // Speech
  useSpeech(currentActivity)

  useEffect(() => {
    setBarStyle({
      backgroundColor: formatBackgroundColor(currentActivity?.backgroundColorFrom),
    })
    setBarClasses(`ion-text-center ${currentActivity && currentActivity.title.length > 80 ? 'bar-lines' : 'bar'}`)
  }, [currentActivity])

  if (currentActivity == null) return <></>

  return (
    <>
      <div
        id="actvitybar"
        className={classnames(
          'flex justify-center items-center align-middle px-5 h-32 w-full absolute text-6xl opacity-100',
          barClasses,
        )}
        style={barStyle}
      >
        <ActivityText text={currentActivity.title} color={currentActivity.textColor} />
      </div>
    </>
  )
}

export default ActivityBar

import React from 'react'
import { IonItem, IonLabel, IonToggle } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { SleepModusHook } from '../../../hooks/settings/useSleepModus'

const SettingsSleepModus: React.FC<SleepModusHook> = (props: SleepModusHook) => {
  const { t } = useTranslation()

  return (
    <IonItem>
      <IonLabel className="font-extrabold">{t('settings.menu.general.dim')}</IonLabel>
      <IonToggle checked={props.enabled} onIonChange={(e) => props.toggleSleepModus(e.detail.checked)} />
    </IonItem>
  )
}

export default SettingsSleepModus

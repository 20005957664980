import { useEffect, useRef, useState } from 'react'
import activitiesHelper from '../../core/helper/activities.helper'
import { Activity } from '../../store/activities/types'

import useActivities from './useActivities'
import moment from 'moment'

export interface CurrentActivityInterface {
  currentActivity: Activity
}
const useCurrentActivity = (): CurrentActivityInterface => {
  const [currentActivity, setCurrentActivity] = useState<Activity>()
  const { activities } = useActivities()
  const timer = useRef(null)

  useEffect(() => {
    timer.current = setInterval(() => {
      const currentActivity = activitiesHelper.findCurrentActivity(moment().format('yyyyMM'), activities)
      setCurrentActivity(currentActivity)
    }, 5000)
    return () => {
      clearInterval(timer.current)
    }
  }, [activities]) // eslint-disable-line react-hooks/exhaustive-deps

  return { currentActivity: currentActivity }
}

export default useCurrentActivity

import React from 'react'
import { Activity } from '../../core/interfaces/activity'
import { formatBackgroundColor, formatColor, truncateSentence } from '../../core/helper/string.helper'
import { IonIcon } from '@ionic/react'
import { timeOutline } from 'ionicons/icons'
import { showDateAsTime } from '../../core/helper/date.helper'

export interface PureWeekActivityInterface {
  activity: Activity
}

const WeekActivity: React.FC<PureWeekActivityInterface> = (props: PureWeekActivityInterface) => {
  const { activity } = props

  return (
    <>
      <div
        className="m-2 p-2 rounded text-white fade-in-up"
        style={{
          color: formatColor(activity.textColor),
          backgroundColor: formatBackgroundColor(activity.backgroundColorFrom),
        }}
      >
        <div className="font-bold fade-in-up">{truncateSentence(activity.title, 25)}</div>
        <div>
          <IonIcon icon={timeOutline} className="mr-2"></IonIcon>
          {showDateAsTime(activity.startDate)}-{showDateAsTime(activity.endDate)}
        </div>
      </div>
    </>
  )
}

export default WeekActivity

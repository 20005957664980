import React, { useEffect, useState } from 'react'
import useClockType from '../../../hooks/settings/clockType'
import useClock from '../../../hooks/settings/useClock'
import useColor from '../../../hooks/settings/useColor'
import { ClockTypeEnum, Color } from '../../../store/settings/types'
import './Center.css'
import i18n from 'i18next'
import Moment from 'react-moment'
import Logo from '../../theme/Logo'

/**
 * The center piece for the analog clock
 */
const Center: React.FC = () => {
  const [date, setDate] = useState(new Date())
  const { dateFormat, timeFormat } = useClock()
  const [tFormat, setTFormat] = useState('')
  const { color: textColor } = { ...useColor(Color.Text) }
  const { type } = useClockType()

  useEffect(() => {
    setTFormat(timeFormat === 'H' ? 'H:mm' : 'h:mm A')
    const timerId = setInterval(() => tick(), 1000)
    return function cleanup() {
      clearInterval(timerId)
    }
  }, [dateFormat, timeFormat])

  function tick() {
    setDate(new Date())
  }

  const showDigitalAndBrand = (
    <>
      {(ClockTypeEnum.DigitalAndAnalog || ClockTypeEnum.Analog) && (
        <div style={{ color: textColor }} className="flex flex-col justify-center">
          <Logo />
          {type === ClockTypeEnum.DigitalAndAnalog && (
            <div className="digital-bottom-clock text-6xl mt-36 text-center">
              <Moment locale={i18n.language} format={tFormat}>
                {date}
              </Moment>
            </div>
          )}
        </div>
      )}
    </>
  )

  return (
    <>
      <div className="clock-center" style={{ backgroundColor: textColor }} />
      {showDigitalAndBrand}
    </>
  )
}

export default Center

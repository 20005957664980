import { Action, ActionCreator } from 'redux'

import {
  TOGGLE_SHOW_DAYPART,
  TOGGLE_SHOW_MINUTES,
  TOGGLE_SHOW_SECONDS,
  TOGGLE_SHOW_MENU,
  SET_VOLUME,
  SET_LANGUAGE,
  SET_THEME,
  SET_DATE_FORMAT,
  SET_TIME_FORMAT,
  SET_FIRST_TIME_USE,
  SET_WEATHER_COUNTRY,
  SET_WEATHER_CITY,
  SET_WEATHER_TEMPERATURE,
  ClockTypeEnum,
  SET_CLOCK_TYPE,
  SET_COLOR,
  SET_BACKGROUND,
  SettingsStateI,
  SET_INITIAL_SETTTING,
  SET_WEATHER_POSITION,
} from './types'

export const toggleDayPartSetting: ActionCreator<Action> = (status: boolean) => {
  return {
    type: TOGGLE_SHOW_DAYPART,
    payload: status,
  }
}

export const toggleSecondsSetting: ActionCreator<Action> = (status: boolean) => {
  return {
    type: TOGGLE_SHOW_SECONDS,
    payload: status,
  }
}

export const toggleMinutesSetting: ActionCreator<Action> = (status: boolean) => {
  return {
    type: TOGGLE_SHOW_MINUTES,
    payload: status,
  }
}

export const setVolume: ActionCreator<Action> = (volume: number) => {
  return {
    type: SET_VOLUME,
    payload: volume,
  }
}

export const toggleMenuSetting: ActionCreator<Action> = (isChecked: boolean) => {
  return {
    type: TOGGLE_SHOW_MENU,
    payload: isChecked,
  }
}

export const setLanguage: ActionCreator<Action> = (code: string) => {
  return {
    type: SET_LANGUAGE,
    payload: code,
  }
}

export const setTheme: ActionCreator<Action> = (theme: string) => {
  return {
    type: SET_THEME,
    payload: theme,
  }
}

export const setDateSetting: ActionCreator<Action> = (dateFormat: string) => {
  return {
    type: SET_DATE_FORMAT,
    payload: dateFormat,
  }
}

export const setTimeSetting: ActionCreator<Action> = (timeFormat: string) => {
  return {
    type: SET_TIME_FORMAT,
    payload: timeFormat,
  }
}

export const setWeatherCountry: ActionCreator<Action> = (country: string) => {
  return {
    type: SET_WEATHER_COUNTRY,
    payload: country,
  }
}

export const setWeatherCity: ActionCreator<Action> = (city: string) => {
  return {
    type: SET_WEATHER_CITY,
    payload: city,
  }
}

export const setWeatherTemperature: ActionCreator<Action> = (temperature: string) => {
  return {
    type: SET_WEATHER_TEMPERATURE,
    payload: temperature,
  }
}

export const setWeatherPosition: ActionCreator<Action> = (position: string) => {
  return {
    type: SET_WEATHER_POSITION,
    payload: position,
  }
}

export const setClockType: ActionCreator<Action> = (type: ClockTypeEnum.Analog) => {
  return {
    type: SET_CLOCK_TYPE,
    payload: type,
  }
}

export const toggleFirstTimeUseSetting: ActionCreator<Action> = (isFirstTimeUse) => {
  return {
    type: SET_FIRST_TIME_USE,
    payload: isFirstTimeUse,
  }
}

export const setTextColor: ActionCreator<Action> = (color: string) => {
  return {
    type: SET_COLOR,
    payload: color,
  }
}

export const setBackground: ActionCreator<Action> = (backgroundColor: string) => {
  return {
    type: SET_BACKGROUND,
    payload: backgroundColor,
  }
}

export const setInitialSettings: ActionCreator<Action> = (payload: SettingsStateI) => {
  return {
    type: SET_INITIAL_SETTTING,
    payload: payload,
  }
}

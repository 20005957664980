import React from 'react'
import { ClockTypeEnum } from '../../store/settings/types'
import { AnalogClock } from '../clocks/analog-clock/AnalogClock'
import DigitalClock from '../clocks/digital-clock/DigitalClocks'

export interface ClockComponentProps {
  type: ClockTypeEnum
  size: number
}

export const ClockComponent: React.FC<ClockComponentProps> = (props: ClockComponentProps) => {
  const { type, size } = props

  switch (type) {
    case ClockTypeEnum.Analog: {
      return <AnalogClock size={size} />
    }

    case ClockTypeEnum.Digital: {
      return <DigitalClock />
    }

    case ClockTypeEnum.DigitalAndAnalog: {
      return <AnalogClock size={size} digital={true} />
    }
  }

  return <DigitalClock />
}

import { Position } from '../../core/enums/position'
import { DayPart } from '../../core/interfaces/DayPart'
import { SleepModus } from '../../core/interfaces/SleepModus'

export const SET_DATE_FORMAT = 'SET_DATE_FORMAT'
export const SET_TIME_FORMAT = 'SET_TIME_FORMAT'
export const TOGGLE_SHOW_DAYPART = 'TOGGLE_SHOW_DAYPART'
export const TOGGLE_SHOW_SECONDS = 'TOGGLE_SHOW_SECONDS'
export const TOGGLE_SHOW_MINUTES = 'TOGGLE_SHOW_MINUTES'
export const TOGGLE_SHOW_MENU = 'TOGGLE_SHOW_MENUs'
export const SET_VOLUME = 'SET_VOLUME'
export const SET_LANGUAGE = 'SET_LANGUAGE'
export const SET_THEME = 'SET_THEME'
export const SET_DATE = 'SET_DATE'
export const SET_TIME = 'SET_TIME'
export const SET_FIRST_TIME_USE = 'SET_FIRST_TIME_USE'
export const SET_WEATHER_COUNTRY = 'SET_WEATHER_COUNTRY'
export const SET_WEATHER_CITY = 'SET_WEATHER_CITY'
export const SET_WEATHER_TEMPERATURE = 'SET_WEATHER_TEMPERATURE'
export const SET_WEATHER_POSITION = 'SET_WEATHER_POSITION'
export const SET_CLOCK_TYPE = 'SET_CLOCK_TYPE'
export const GET_WEATHER = 'GET_WEATHER'
export const SET_COLOR = 'SET_COLOR'
export const SET_BACKGROUND = 'SET_BACKGROUND'
export const SET_INITIAL_SETTTING = 'SET_INITIAL_SETTTING'
export const TOGGLE_SLEEP_MODUS = 'TOGGLE_SLEEP_MODUS'
export const SET_SLEEP_START = 'SET_SLEEP_START'
export const SET_SLEEP_END = 'SET_SLEEP_END'
export const SET_DAYPART_START = 'SET_DAYPART_START'

export enum DateFormat {
  DayMonthFull = 'D MMMM',
  DayMonthYearFull = 'D MMMM yyyy',
  DayMonthDotFull = 'D. MMMM',
  DayMonthDotYearFull = 'D. MMMM yyyy',
  DayMonthYearShort = 'D-MM-yyyy',
  MonthDayYearShort = 'MM-D-yyyy',
}

export enum TimeFormat {
  H12 = 'h',
  H24 = 'H',
}

export enum ClockTypeEnum {
  Digital,
  Analog,
  DigitalAndAnalog,
  Day,
  Week,
}

export interface ClockColorI {
  textColor: string
  backgroundColor: string
}

export interface ClockSettingsI {
  type: ClockTypeEnum
  showDayPart: boolean
  showSeconds: boolean
  showMinutes: boolean
}

export enum Language {
  dutch = 'nl',
  german = 'de',
  english = 'en',
  french = 'fr',
  spanish = 'es',
  danish = 'da',
}

export enum Temperature {
  Celsius = 'm',
  Fahrenheit = 'f',
}

export enum TemperatureUnit {
  m = 'C',
  f = 'F',
}

export enum Countries {
  default = 'nl',
}

export enum Theme {
  Normal = 'normal',
  Light = 'light',
  Dark = 'dark',
}

export enum DayPartKeys {
  Morning = 'morning',
  PreAfternoon = 'preAfternoon',
  Afternoon = 'afternoon',
  PostAfternoon = 'postAfternoon',
  Evening = 'evening',
  Night = 'night',
}

export interface Weather {
  country: string
  city: string
  temperature: Temperature
  position: Position
}

export interface SettingsStateI {
  theme: Theme
  language: Language
  showMenu: boolean
  sleepModus: SleepModus
  volume: number
  clock: ClockSettingsI
  colors: ClockColorI
  dateFormat: DateFormat
  timeFormat: TimeFormat
  dayParts: DayPart
  firstTimeUse: boolean
  weather: Weather
}

export interface Album {
  duration: number
}

export interface Photo {
  url: string
  order: number
  pictureData?: string
  pictureUrl?: string
}

export enum Color {
  Text = 'textColor',
  Background = 'backgroundColor',
}

import React, { useEffect, useState } from 'react'
import { connect, Room } from 'twilio-video'
import useLogin from '../../hooks/settings/useLogin'
import twilioApi from '../../services/api/twiliocall-api'
import Lobby from './Lobby'
import VideoRoom from './VideoRoom'

const TwilioVideo = () => {
  const [room, setRoom] = useState<Room | null>(null)
  const [calling, setCalling] = useState<boolean>(false)
  const { token, user } = useLogin()

  useEffect(() => {
    startCall()
    return () => {
      stopCall()
    }
  }, []) // eslint-disable-line

  const startCall = () => {
    if (!calling) {
      console.log('start calling')
      setCalling(true)
      twilioApi.createSession(token, user).then((response) => {
        const token = response.data
        connect(token, {
          name: user,
          audio: true,
          video: true,
        }).then((response) => {
          setRoom(response)
          setCalling(false)
        })
      })
    } else {
      console.log('calling in progress')
    }
  }

  const stopCall = () => {
    room?.disconnect()
    setRoom(null)
    setCalling(false)
  }

  if (room) {
    return <VideoRoom room={room} stopCall={stopCall} />
  }

  return <Lobby startCall={startCall} calling={calling} />
}

export default TwilioVideo

import * as i18nIsoCountries from 'i18n-iso-countries'
// eslint-disable-next-line
i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/de.json'))
// eslint-disable-next-line
i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/da.json'))
// eslint-disable-next-line
i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'))
// eslint-disable-next-line
i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/fr.json'))
// eslint-disable-next-line
i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/nl.json'))
// eslint-disable-next-line
i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/es.json'))

type sortedCountriesType = { key: string; value: string }[]

export const sortedCountries = (language: string): sortedCountriesType => {
  return Object.entries(i18nIsoCountries.getNames(language, { select: 'official' }))
    .map(([key, value]) => {
      return {
        key: key,
        value: value,
      }
    })
    .sort((a, b) => a.value.localeCompare(b.value))
}

import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { SleepModus } from '../../core/interfaces/SleepModus'
import { toggleSleepModusSetting, updateSleepStart, updateSleepEnd } from '../../store/settings/actions/sleep-actions'
import { SettingsStateI } from '../../store/settings/types'
import { RootState } from '../../store/store'
import useDateInterval from '../interval/useDateInterval'

export interface SleepModusHook extends SleepModus {
  isSleeping: boolean
  toggleSleepModus: (isChecked: boolean) => void
  setSleepStart: (time: string) => void
  setSleepEnd: (time: string) => void
}

const isSleeping = (date: Date, sleepModus: SleepModus): boolean => {
  if (!sleepModus) {
    return false
  }

  const start = moment(sleepModus.start, 'hh:mm')
  const end = moment(sleepModus.end, 'hh:mm')

  if (start.diff(end) > 0) {
    end.add('1', 'days')
  }

  return moment(date).isBetween(start, end) && sleepModus.enabled
}

const useSleepModus = (): SleepModusHook => {
  const { sleepModus } = useSelector((state: RootState) => state.settings) as SettingsStateI

  const dispatch = useDispatch()

  const toggleSleepModus = (isChecked: boolean): void => {
    dispatch(toggleSleepModusSetting(isChecked))
  }

  const setSleepStart = (time: string): void => {
    dispatch(updateSleepStart(time))
  }

  const setSleepEnd = (time: string): void => {
    dispatch(updateSleepEnd(time))
  }

  const { date } = useDateInterval(60)

  return {
    ...sleepModus,
    isSleeping: isSleeping(date, sleepModus),
    toggleSleepModus,
    setSleepStart,
    setSleepEnd,
  }
}

export default useSleepModus

import { OpenNativeSettings } from '@ionic-native/open-native-settings'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNetwork } from '../../../hooks/network/useNetwork'
import SetupButton from '../button/SetupButton'
import wifiImage from './wifi-image.svg'

import './wifi-setup.css'

const WifiSetup: React.FC = () => {
  const connected = useNetwork()
  const { t } = useTranslation()

  const openNativeWifiSettings = async () => {
    await OpenNativeSettings.open('wifi')
  }

  return (
    <div className="wifi-setup h-5/6">
      <div className="ml-12">
        <div className="text-4xl font-sans mb-10">
          {connected ? t('setup.wifi.title.connected') : t('setup.wifi.title.connect')}
        </div>

        <SetupButton onClick={() => openNativeWifiSettings()} showAfter={false}>
          {connected ? t('setup.wifi.button.connected') : t('setup.wifi.button.connect')}
        </SetupButton>
      </div>

      <div className="image-wrapper">
        <img src={wifiImage} alt="Wifi" />
      </div>
    </div>
  )
}

export default WifiSetup

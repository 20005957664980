import React from 'react'
import { IonContent, IonPage } from '@ionic/react'
import SettingsMenu from './settings-menu/SettingsMenu'
import { VERSION } from '../../core/const'

interface Props {
  children?: React.ReactNode
}
const SettingsWrapper: React.FC<Props> = ({ children }: Props) => {
  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="absolute top-5 right-5 text-sm">
          {VERSION} - p:{window.devicePixelRatio} w:{window.outerWidth} h:{window.outerHeight}
        </div>
        <div className="flex h-full">
          <div className="w-3/12 bg-blue-100">
            <SettingsMenu />
          </div>
          <div className="w-9/12 p-10 pl-4 bg-white">{children}</div>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default SettingsWrapper

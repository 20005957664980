import React, { useContext } from 'react'
import { IonIcon } from '@ionic/react'
import { settings, sunny, ellipsisHorizontalCircle, contrast, time, colorFill, help } from 'ionicons/icons'
import clsx from 'clsx'
import SettingType from '../../../core/enums/SettingsType'
import settingsMenuContext from '../../../core/context/settingsMenuContext'
import './SettingsMenuItem.css'

export interface SettingsMenuProps {
  /** The title of the menu item */
  title?: string
  /** The description of the menu item */
  description: string
  /** Status of the menu item */
  active?: boolean
  /** the type of the settings menu item */
  type?: SettingType
}

const getIconForType = (type: SettingType) => {
  switch (type) {
    case SettingType.General:
      return settings
    case SettingType.Themes:
      return colorFill
    case SettingType.Weather:
      return sunny
    case SettingType.Extra:
      return ellipsisHorizontalCircle
    case SettingType.DayParts:
      return contrast
    case SettingType.Clock:
      return time
    case SettingType.Help:
      return help
    default:
      return settings
  }
}

const SettingsMenuItem: React.FC<SettingsMenuProps> = (props: SettingsMenuProps) => {
  const { title, description, active, type } = props
  const { setSelectedSetting } = useContext(settingsMenuContext)

  return (
    <button
      className={clsx('link flex-1 border-b-2 border-solid last:border-0 border-gray-300', { 'bg-blue-400': active })}
      onClick={() => setSelectedSetting(type)}
    >
      <div className="h-full">
        <div className="flex items-center h-full">
          <div className="p-5 text-5xl dc7:text-4xl">
            <IonIcon
              icon={getIconForType(type)}
              className={clsx(`icon__${type}`, {
                'icon-active': active,
              })}
            />
          </div>
          <div className="pr-5 text-left">
            <div
              className={clsx('text-xl dc7:text-base font-bold', {
                'text-white': active,
              })}
            >
              {title}
            </div>
            <p
              className={clsx('text-base dc7:text-sm mt-2', {
                'text-black': active,
                'text-gray-600': !active,
              })}
            >
              {description}
            </p>
          </div>
        </div>
      </div>
    </button>
  )
}

SettingsMenuItem.defaultProps = {
  active: false,
  type: SettingType.General,
}

export default SettingsMenuItem

import React from 'react'
import { ClockTypeEnum } from '../../store/settings/types'
import Photo from '../photo/Photo'
import { ClockComponent } from './ClockComponent'
import SmallDayComponent from './SmallDayComponent'

export interface ClockPhotoComponentProps {
  type: ClockTypeEnum
  clockSize: number
  pictureData?: string
  pictureUrl?: string
}

export const ClockPhotoComponent: React.FC<ClockPhotoComponentProps> = (props: ClockPhotoComponentProps) => {
  const { type, clockSize, pictureData, pictureUrl } = props
  return (
    <>
      <div className="w-1/2 p-5 relative">
        <ClockComponent type={type} size={clockSize} />

        {(type === ClockTypeEnum.Analog || type === ClockTypeEnum.DigitalAndAnalog) && <SmallDayComponent />}
      </div>

      <div className="w-1/2" style={{ height: 'calc(100% - 8rem)' }}>
        <Photo pictureData={pictureData} pictureUrl={pictureUrl} />
      </div>
    </>
  )
}

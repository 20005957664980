import React from 'react'
import useColor from '../../hooks/settings/useColor'
import { Color } from '../../store/settings/types'
import './Logo.css'

const Logo: React.FC = () => {
  const { color: textColor } = { ...useColor(Color.Text) }

  return (
    <div className="digital-bottom-logo text-xl text-center" style={{ color: textColor }}>
      DayClocks
    </div>
  )
}

export default Logo

import React from 'react'
import useColor from '../../../hooks/settings/useColor'
import { Color } from '../../../store/settings/types'
import { calculateHandAngle } from './calculateHandAngle'
import './Hand.css'

export enum Hands {
  Second = 'second',
  Minute = 'minute',
  Hour = 'hour',
}

export interface HandInterface {
  /** The hand type */
  type: Hands
  /** The hour */
  hours: number
  /** The minutes */
  minutes: number
  /** The seconds */
  seconds: number
}

/**
 * The hand for the clock, being seconds, minutes or hours
 */
const Hand: React.FC<HandInterface> = (props: HandInterface) => {
  const { type, hours, minutes, seconds } = props
  const handAngle = calculateHandAngle({ type, hours, minutes, seconds })
  const { color: textColor } = { ...useColor(Color.Text) }

  const handStyle = {
    transform: `rotate(${handAngle}deg)`,
    backgroundColor: textColor,
  }

  return <div className={`clock-hand clock-hand__${type}`} style={handStyle}></div>
}

export default Hand

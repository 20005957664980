import { useDispatch, useSelector } from 'react-redux'
import { IClockType } from '../../core/interfaces/settings'
import { ClockTypeEnum } from '../../store/settings/types'
import { setClockType } from '../../store/settings/actions'
import { RootState } from '../../store/store'

const useClockType = (): IClockType => {
  const dispatch = useDispatch()
  const { type } = useSelector((state: RootState) => state.settings.clock)

  const setType = (type: ClockTypeEnum) => {
    dispatch(setClockType(type))
  }
  return { type, setType }
}

export default useClockType

import React from 'react'
import './Photo.css'
import classNames from 'classnames'

export interface PhotoProps {
  hidden?: boolean
  pictureData?: string
  pictureUrl?: string
  stylePicture?: string
}

const Photo: React.FC<PhotoProps> = (props: PhotoProps) => {
  const { pictureData, pictureUrl, stylePicture } = props
  let source
  if (pictureData && pictureData !== '') {
    source = `data:image/png;base64,${pictureData}`
  } else if (pictureUrl && pictureUrl !== '') {
    source = pictureUrl
  }
  if (!source) {
    return <></>
  }

  return <img className={classNames('photo object-cover w-full', stylePicture)} src={source} alt="just a pict." />
}

export default Photo

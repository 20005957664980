import ReactGA from 'react-ga4'

interface useAnalyticsInterface {
  trackEvent: (category: string, action: string, label?: string) => void
  trackView: (page: string) => void
}

const useAnalytics = (): useAnalyticsInterface => {
  const trackEvent = (category: string, action: string, label?: string) => {
    ReactGA.initialize('G-PB252C2GJY')
    ReactGA.event({
      category: category,
      action: action,
      label: label,
      nonInteraction: true, // optional, true/false
      transport: 'xhr', // optional, beacon/xhr/image
    })
  }

  const trackView = (page: string) => {
    ReactGA.initialize('G-PB252C2GJY')
    ReactGA.send({ hitType: 'pageview', page: page })
  }

  return { trackEvent, trackView }
}

export default useAnalytics

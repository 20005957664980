import { useDispatch } from 'react-redux'
import { getActivities } from '../../store/activities/activitiesSlice'
import { useEffect } from 'react'
import { UpdateFirebase } from '../../core/interfaces/firebase'

const useUpdateActivities = (update: UpdateFirebase): void => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (update) {
      dispatch(getActivities())
    }
  }, [dispatch, update])
}

export default useUpdateActivities

import { useDispatch, useSelector } from 'react-redux'
import { SettingsVolume } from '../../core/interfaces/settings'
import { setVolume } from '../../store/settings/actions'
import { SettingsStateI } from '../../store/settings/types'
import { RootState } from '../../store/store'

const useVolume = (): SettingsVolume => {
  const { volume } = useSelector((state: RootState) => state.settings) as SettingsStateI

  const dispatch = useDispatch()

  const handleChange = (value: number): void => {
    dispatch(setVolume(value))
  }
  return { volume, handleChange }
}

export default useVolume

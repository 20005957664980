import React from 'react'
import { useTranslation } from 'react-i18next'
import { IonContent, IonPage } from '@ionic/react'
import SetupButton from '../../components/setup/button/SetupButton'
import SetupHeader from '../../components/setup/header/SetupHeader'
import Globe from '../../components/setup/globe/Globe'

import './SetupLanguage.css'
import LanguageSelector from '../../components/setup/language-selector/LanguageSelector'
import useLanguage from '../../hooks/settings/useLanguage'

const SetupLanguage: React.FC = () => {
  const { t } = useTranslation()
  const { language } = useLanguage()

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="bg-blue-200 w-full h-full p-2 pt-10">
          <SetupHeader />
          <div className="rounded-md bg-white m-10 h-5/6 pt-10 pl-10 relative overflow-hidden">
            <Globe className="-right-10" />
            <SetupButton className="setup-button-next__position" disabled={language === null} to="/setup/wifi">
              {t('buttons.next')}
            </SetupButton>
            <LanguageSelector />
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default SetupLanguage

import { DayPart } from '../core/interfaces/DayPart'
import { TimeFrame } from '../core/interfaces/TimeFrame'
import { DayPartKeys, Language } from '../store/settings/types'

/**
 * Gets the default values for the dayparts
 * - Morgen 07:00 - 09:00
 * - Vormittag 09:00 - 12:00
 * - Mittag 12:00 - 13:00
 * - Nachmittag 13:00 - 18:00
 * - Abend 18:00 - 23:00
 * - Nacht 23:00 - 07:00
 *
 * @param dayPart
 */
const getDefaultDayPartTimeFrame = (dayPart: DayPartKeys): TimeFrame => {
  switch (dayPart) {
    case DayPartKeys.Morning:
      return {
        start: '07:00',
        end: '12:00',
      }
      break
    case DayPartKeys.PreAfternoon:
      return {
        start: '09:00',
        end: '12:00',
      }
      break
    case DayPartKeys.Afternoon:
      return {
        start: '12:00',
        end: '18:00',
      }
      break
    case DayPartKeys.PostAfternoon:
      return {
        start: '13:00',
        end: '18:00',
      }
      break
    case DayPartKeys.Evening:
      return {
        start: '18:00',
        end: '23:00',
      }
      break
    case DayPartKeys.Night:
      return {
        start: '23:00',
        end: '07:00',
      }
      break
  }
}

const validateGermanDayPartSettings = (dayParts: DayPart) => {
  return (
    dayParts[DayPartKeys.Morning].end === dayParts[DayPartKeys.PreAfternoon].start &&
    dayParts[DayPartKeys.PreAfternoon].end === dayParts[DayPartKeys.Afternoon].start &&
    dayParts[DayPartKeys.Afternoon].end === dayParts[DayPartKeys.PostAfternoon].start &&
    dayParts[DayPartKeys.PostAfternoon].end === dayParts[DayPartKeys.Evening].start &&
    dayParts[DayPartKeys.Evening].end === dayParts[DayPartKeys.Night].start &&
    dayParts[DayPartKeys.Night].end === dayParts[DayPartKeys.Morning].start
  )
}

const validateGlobalDayPartSettings = (dayParts: DayPart) => {
  return (
    dayParts[DayPartKeys.Morning].end === dayParts[DayPartKeys.Afternoon].start &&
    dayParts[DayPartKeys.Afternoon].end === dayParts[DayPartKeys.Evening].start &&
    dayParts[DayPartKeys.Evening].end === dayParts[DayPartKeys.Night].start &&
    dayParts[DayPartKeys.Night].end === dayParts[DayPartKeys.Morning].start
  )
}

const validDayPartSettings = (language: Language, dayParts: DayPart): boolean => {
  if (language === Language.german) {
    return validateGermanDayPartSettings(dayParts)
  }

  return validateGlobalDayPartSettings(dayParts)
}

const dayPartService = {
  getDefaultDayPartTimeFrame,
  validDayPartSettings,
}

export default dayPartService

import { IonButton, IonInput, IonItem, IonLabel, IonList, IonListHeader, IonText, IonToast } from '@ionic/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Login as LoginInterface } from '../../../core/interfaces/settings'

const SettingsAdmin: React.FC<LoginInterface> = ({
  surname,
  username,
  user,
  password,
  isError,
  loginSucess,
  setUsername,
  setPassword,
  handleLogin,
  handleLogout,
}: LoginInterface) => {
  const { t } = useTranslation()

  return (
    <>
      <IonListHeader>
        <IonLabel className="text-xl dc7:text-lg font-bold">{t('settings.account.title')}</IonLabel>
      </IonListHeader>

      <IonToast
        isOpen={isError}
        message={t('settings.account.invalid_credential')}
        color="danger"
        position="top"
        duration={2000}
        cssClass="text-center"
      />

      <IonToast
        isOpen={loginSucess}
        message={t('settings.account.login_success', { surname: surname })}
        color="success"
        position="top"
        duration={2000}
        cssClass="text-center"
      />

      <IonList lines="full">
        <IonItem className="dc7:text-sm">
          {surname ? (
            <>
              <IonButton slot="end" onClick={handleLogout}>
                {t('settings.account.button.logout')}
              </IonButton>
              <IonText>{t('settings.account.connected', { user: user })}</IonText>
            </>
          ) : (
            <>
              <IonInput
                value={username}
                placeholder={t('settings.account.label.username')}
                onIonChange={(e) => setUsername(e.detail.value)}
              />
              <IonInput
                value={password}
                type="password"
                placeholder={t('settings.account.label.password')}
                onIonChange={(e) => setPassword(e.detail.value)}
              />
              <IonButton onClick={handleLogin}>{t('settings.account.button.login')}</IonButton>
            </>
          )}
        </IonItem>
      </IonList>
    </>
  )
}

export default SettingsAdmin

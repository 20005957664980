import { IonContent, IonPage } from '@ionic/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import AccountSetup from '../../components/setup/account-setup/AccountSetup'
import SetupButton from '../../components/setup/button/SetupButton'
import SetupHeader from '../../components/setup/header/SetupHeader'
import { useLogin } from '../../hooks/settings/settingsHook'

const SetupAccount: React.FC = () => {
  const { t } = useTranslation()
  const { token } = useLogin()

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="bg-blue-200 w-full h-full p-2 pt-10">
          <SetupHeader />
          <div className="rounded-md bg-white m-10 h-5/6 pt-10 pl-10 relative overflow-hidden dc7:m-5 dc7:pl-5 dc7:pt-5">
            <AccountSetup {...useLogin()} />

            <SetupButton className="setup-button-prev__position" to="/setup/wifi" isBack={true}>
              {t('buttons.prev')}
            </SetupButton>
            <SetupButton
              className="setup-button-next__position"
              to="/setup/ready"
              // onClick={() => finishSetup()}
            >
              {token ? t('setup.account.button.next_with_account') : t('setup.account.button.next_without_account')}
            </SetupButton>
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default SetupAccount

import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import { store } from './store/store'
import App from './App'
import { loadStoredState, storeSettingsState, storeUserState } from './services/storage-service'
import { setInitialSettings } from './store/settings/actions'
import { SettingsStateI } from './store/settings/types'
import { setInitialUser, UserStateI } from './store/user/userSlice'
import { IonApp, IonRouterOutlet } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { AndroidFullScreen } from '@awesome-cordova-plugins/android-full-screen'
import dayPartService from './services/daypart-service'
import { initialDayParts } from './store/settings/reducer'

const AppContainer: React.FC = () => {
  useEffect(() => {
    async function setFullScreen() {
      await AndroidFullScreen.immersiveMode()
    }

    const setDevicePixelRation = () => {
      console.log('window.devicePixelRatio', window.devicePixelRatio, window.outerWidth, window.outerHeight)
      console.log('fontSize', 16 / window.devicePixelRatio)
      console.log('innerWidth', window.outerWidth)
      console.log('innerHeight', window.outerHeight)

      console.log('innerWidth ratio', window.outerWidth / 1280)
      console.log('innerHeight ratio', window.outerHeight / 800)

      console.log('fontSize', (16 * window.outerWidth) / (window.devicePixelRatio * 1280))

      const htmlElement = document.getElementsByTagName('html')[0] as HTMLElement
      const defaultFontSize = (16 * window.outerWidth) / (window.devicePixelRatio * 1280)
      htmlElement.style.fontSize = `${defaultFontSize}px`
      window.resizeTo(600, 400)
    }

    async function initLoadStoredState() {
      const storedState = await loadStoredState()
      if (storedState.settings) {
        if (
          dayPartService.validDayPartSettings(storedState.settings.language, storedState.settings.dayParts) === true
        ) {
          store.dispatch(setInitialSettings(storedState.settings))
        } else {
          store.dispatch(
            setInitialSettings({
              ...storedState.settings,
              dayParts: initialDayParts,
            }),
          )
        }
      }
      if (storedState.user) {
        store.dispatch(setInitialUser(storedState.user))
      }
    }
    // init an load state from storage if available
    let currentSettingsState: SettingsStateI = null
    let currentUserState: UserStateI = null

    setFullScreen()
    setDevicePixelRation()
    initLoadStoredState()

    return store.subscribe(() => {
      // persist the settings state to storage
      const previousSettingsState = currentSettingsState
      currentSettingsState = store.getState().settings
      if (previousSettingsState && previousSettingsState !== currentSettingsState) {
        storeSettingsState(currentSettingsState)
      }

      // persist the user state to storage
      const previousUserState = currentUserState
      currentUserState = store.getState().user
      if (previousUserState && previousUserState !== currentUserState) {
        storeUserState(currentUserState)
      }
    })
  }, [])

  return (
    <Provider store={store}>
      <IonApp>
        <IonReactRouter>
          <IonRouterOutlet animated={false}>
            <App />
          </IonRouterOutlet>
        </IonReactRouter>
      </IonApp>
    </Provider>
  )
}

export default AppContainer

interface LocalStorage {
  key: string
  value: string
}

export interface DesktopStorage {
  set: (data: LocalStorage) => void
  get: (key: string) => Promise<string>
}

const desktopStorage: DesktopStorage = {
  set: ({ key, value }: LocalStorage): void => {
    localStorage.setItem(key, value)
  },
  get: (key: string): Promise<string> => {
    return new Promise((resolve) => resolve(localStorage.getItem(key)))
  },
}

export { desktopStorage }

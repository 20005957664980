import React from 'react'
import PropTypes from 'prop-types'
import { IonItem, IonLabel, IonList, IonListHeader, IonSelect, IonSelectOption } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { SettingsLanguage as SettingsLanguageInterface } from '../../../core/interfaces/settings'
import { LANGUAGES } from '../../../core/countries'

const SettingsLanguage: React.FC<SettingsLanguageInterface> = ({ language, handleChange }) => {
  const { t } = useTranslation()

  return (
    <>
      <IonListHeader className="text-xl font-bold">{t('settings.language.title')}</IonListHeader>

      <IonList lines="full">
        <IonItem className="dc7:text-sm">
          <IonLabel>{t('settings.language.choose')}</IonLabel>
          <IonSelect
            slot="end"
            value={language}
            onIonChange={(e) => handleChange(e.detail.value)}
            cancelText={t(`Cancel`)}
            okText={t(`Ok`)}
          >
            {LANGUAGES.map((code: string, index: number) => (
              <IonSelectOption value={code} key={index}>
                {t(`languages.${code}`)}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
      </IonList>
    </>
  )
}

SettingsLanguage.propTypes = {
  language: PropTypes.oneOf(LANGUAGES),
  handleChange: PropTypes.func.isRequired,
}

export default SettingsLanguage

/* eslint-disable @typescript-eslint/no-explicit-any */

import axios, { AxiosRequestConfig, AxiosPromise } from 'axios'

const API_URL = 'https://video-api.dayclocks.nl'

const instance = axios.create({
  baseURL: API_URL,
})

const request = (options: AxiosRequestConfig): AxiosPromise<any> => {
  return instance(options)
}

const createSession = (token: string | null, username: string | null): AxiosPromise<any> => {
  return request({
    url: '/api/twilio',
    method: 'POST',
    data: {
      username: username,
    },
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
}
const twilioApi = {
  createSession,
}

export default twilioApi

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { PhotoRequest } from '../../core/interfaces/request'
import photoApi from '../../services/api/photoApi'
import { Photo } from '../settings/types'
import { logout } from '../user/userSlice'
import { PHOTO_LOAD } from './types'

export interface PhotoState {
  isError: boolean
  photo: Photo
}

const initialState: PhotoState = {
  isError: false,
  photo: undefined,
}

export const getPhoto = createAsyncThunk(PHOTO_LOAD, async (request: PhotoRequest) => {
  const { data } = await photoApi.getPhoto(request)

  const photo = {
    photo: data,
  }

  return photo
})

export const photosSlice = createSlice({
  name: 'photo_photo',
  initialState,
  reducers: {},
  extraReducers: {
    [getPhoto.fulfilled.toString()]: (state, { payload }) => {
      state.isError = false
      state.photo = payload.photo
    },
    [getPhoto.rejected.toString()]: (state) => {
      state.isError = true
    },
    [logout.fulfilled.toString()]: () => {
      return initialState
    },
  },
})

export default photosSlice.reducer

import { DayPartKeys, Language } from '../../store/settings/types'

export const getDayPartsForLanguage = (language: string): DayPartKeys[] => {
  let dayPartKeys = [
    DayPartKeys.Morning,
    DayPartKeys.PreAfternoon,
    DayPartKeys.Afternoon,
    DayPartKeys.PostAfternoon,
    DayPartKeys.Evening,
    DayPartKeys.Night,
  ]
  // remove the dayParts only usable for German
  if (language !== Language.german) {
    dayPartKeys = [DayPartKeys.Morning, DayPartKeys.Afternoon, DayPartKeys.Evening, DayPartKeys.Night]
  }

  return dayPartKeys
}

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { LoginCredential } from '../../core/interfaces/settings'
import { login as loginService } from '../../services/api/UserApi'
import { LOGIN, LOGOUT, SET_INITIAL_USER } from './types'

export interface UserStateI {
  isError: boolean
  loginSucess: boolean
  surname: string
  user?: string
  token?: string
}

const initialState: UserStateI = {
  isError: false,
  loginSucess: false,
  surname: '',
  user: '',
  token: '',
}

export const login = createAsyncThunk(LOGIN, async (credential: LoginCredential) => {
  const { data } = await loginService(credential)
  data.user = data.username
  return data
})

export const logout = createAsyncThunk(LOGOUT, () => {
  return initialState
})

export const setInitialUser = createAsyncThunk(SET_INITIAL_USER, (payload: UserStateI) => {
  return payload
})

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: {
    [login.pending.toString()]: (state) => {
      state.isError = false
      state.loginSucess = false
    },
    [login.fulfilled.toString()]: (state, { payload }) => {
      state.isError = false
      state.loginSucess = true
      state.token = payload.token
      state.user = payload.user
      state.surname = payload.surname || ''
    },
    [login.rejected.toString()]: (state) => {
      state.isError = true
      state.loginSucess = false
    },
    [setInitialUser.fulfilled.toString()]: (state, { payload }) => {
      state.isError = payload.isError
      state.loginSucess = payload.loginSucess
      state.token = payload.token
      state.user = payload.user
      state.surname = payload.surname || ''
    },
    [logout.fulfilled.toString()]: () => {
      return initialState
      // state.album moet leeg
      // state.photos moet leeg
      // state.allActivities moet leeg
      // state.activities moet leeg
    },
  },
})

export default userSlice.reducer

import React, { useEffect } from 'react'
import { setupIonicReact } from '@ionic/react'
import { useSelector, Provider } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import './theme/variables.css'

import Home from './pages/Home'
import Goodmorning from './pages/Goodmorning'
import SettingsPage from './pages/settings/SettingsPage'
import SetupLanguage from './pages/setup/SetupLanguage'
import SetupWifi from './pages/setup/SetupWifi'
import SetupAccount from './pages/setup/SetupAccount'
import SetupReady from './pages/setup/SetupReady'
import { RootState, store } from './store/store'
import { KeepAwake } from '@capacitor-community/keep-awake'
import { Capacitor } from '@capacitor/core'
import DebugPage from './pages/Debug'

import * as firebase from 'firebase/app'
import { getDatabase } from 'firebase/database'
import { firebaseConfig } from './core/const'
import TwilioVideo from './pages/twilio/TwilioVideo'
import useTwilioCallFirebase from './hooks/firebase/useTwilioCallFirebase'
import useHeartBeat from './hooks/firebase/useHeartBeatFirebase'
import { useUpdatesFirebase } from './hooks/firebase/useUpdatesFirebase'
import useUpdateActivities from './hooks/activities/useUpdateActivities'

const App: React.FC = () => {
  const { i18n } = useTranslation()
  const { language } = useSelector((state: RootState) => state.settings)

  setupIonicReact({
    mode: 'md',
  })

  const keepAwake = async () => {
    if (Capacitor.isNativePlatform()) {
      await KeepAwake.keepAwake()
    }
  }

  // Init the firebase connection
  const fbApp = firebase.initializeApp(firebaseConfig)
  const fbDatabase = getDatabase(fbApp)
  const result = useUpdatesFirebase(fbDatabase)

  // Init the heartBeat
  useHeartBeat()

  useUpdateActivities(result)
  const twilioCall = useTwilioCallFirebase(fbDatabase)

  useEffect(() => {
    keepAwake()
    if (Capacitor.isNativePlatform()) {
      window.screen.orientation.lock('landscape')
    }

    i18n.changeLanguage(language)
  }, [i18n, language])

  const firstTimeUse = localStorage.getItem('firstTimeUse') === null || localStorage.getItem('firstTimeUse') === 'true'
  if (firstTimeUse) {
    return (
      <>
        <Route exact path="/" component={SetupLanguage} />
        <Route exact path="/setup/language" component={SetupLanguage} />
        <Route exact path="/setup/wifi" component={SetupWifi} />
        <Route exact path="/setup/account" component={SetupAccount} />
        <Route exact path="/setup/ready" component={SetupReady} />
        <Route exact path="/settings" component={SettingsPage} />
        <Redirect to="/" />
      </>
    )
  }

  if (twilioCall?.activeCall === true) {
    return (
      <>
        <Route exact path="/" component={TwilioVideo} />
        <Redirect to="/" />
      </>
    )
  }

  return (
    <Provider store={store}>
      <Route exact path="/" component={Home} />
      <Route exact path="/home" component={Home} />
      <Route exact path="/goodmorning" component={Goodmorning} />
      <Route exact path="/debug" component={DebugPage} />
      <Route exact path="/settings" component={SettingsPage} />
    </Provider>
  )
}

export default App

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { AlbumRequest } from '../../core/interfaces/request'
import albumApi from '../../services/api/AlbumApi'
import { ALBUM_LOAD } from '../activities/types'
import { Photo } from '../settings/types'
import { logout } from '../user/userSlice'

export interface AlbumState {
  isError: boolean
  albumId: string
  duration: number
  title: string
  photos: Photo[]
}

const initialState: AlbumState = {
  isError: false,
  albumId: null,
  duration: 10,
  title: null,
  photos: [],
}

export const getAlbum = createAsyncThunk(ALBUM_LOAD, async (request: AlbumRequest) => {
  const { data: albumData } = await albumApi.getAlbum(request)
  const { data: albumPhotoData } = await albumApi.getPhotos(request)

  const photos = albumPhotoData.map((photo) => photo.url)

  const album = {
    albumId: request.albumId,
    duration: albumData.duration,
    title: albumData.title,
    photos: photos,
  }

  return album
})

export const photosSlice = createSlice({
  name: 'album_photos',
  initialState,
  reducers: {},
  extraReducers: {
    [getAlbum.fulfilled.toString()]: (state, { payload }) => {
      state.isError = false
      state.albumId = payload.albumId
      state.duration = payload.duration
      state.title = payload.title
      state.photos = payload.photos
    },
    [getAlbum.rejected.toString()]: (state) => {
      state.isError = true
    },
    [logout.fulfilled.toString()]: () => {
      return initialState
    },
  },
})

export default photosSlice.reducer

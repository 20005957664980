import { IonContent, IonPage } from '@ionic/react'
import React from 'react'

const DebugPage: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="bg-black text-white w-full h-full p-5">
          <h1>debug</h1>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default DebugPage

import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { WeatherTimer } from '../../core/const'
import useColor from '../../hooks/settings/useColor'
import useWeatherForecast from '../../hooks/settings/useWeatherForecast'
import { Color } from '../../store/settings/types'
import { getWeather } from '../../store/settings/weatherSlice'
import { useTime } from '../clocks/analog-clock/useTime'
import WeatherBar from './WeatherBar'

const Weather: React.FC = () => {
  const { hours, minutes } = useTime()
  const dispatch = useDispatch()
  const { weather, temperature, country, city, language } = useWeatherForecast()

  useEffect(() => {
    if (WeatherTimer.includes(`${hours}:${minutes}`)) {
      dispatch(getWeather({ country, city, unit: temperature, language }))
    }
  }, [city, country, dispatch, hours, language, minutes, temperature])

  const { color: textColor } = { ...useColor(Color.Text) }

  return (
    <div className="absolute bottom-0 right-0 mr-5 w-1/2 h-20" style={{ color: textColor }}>
      <WeatherBar {...{ weather, temperature }} />
    </div>
  )
}

export default Weather

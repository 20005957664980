import React from 'react'
import { useTranslation } from 'react-i18next'
import useCurrentDayPart from '../../hooks/clock/useCurrentDayPart'

const DayPart: React.FC = () => {
  const { t } = useTranslation()
  const currentDayPart = useCurrentDayPart()

  return <>{currentDayPart && t(`settings.menu.dayparts.${currentDayPart}`)}</>
}

export default DayPart

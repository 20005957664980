import { useState, useEffect } from 'react'

export interface TimeInterface {
  hours: number
  minutes: number
  seconds: number
}

export const useTime = (): TimeInterface => {
  const [state, setState] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  })

  useEffect(() => {
    tick()
    const timerId = setInterval(() => tick(), 1000)
    return function cleanup() {
      clearInterval(timerId)
    }
  }, [])

  function tick() {
    const date = new Date()
    setState({
      hours: date.getHours() > 12 ? date.getHours() - 12 : date.getHours(),
      minutes: date.getMinutes(),
      seconds: date.getSeconds(),
    })
  }
  return state as TimeInterface
}

import { IonFab, IonFabButton, IonIcon } from '@ionic/react'
import { call } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import { Participant, Room } from 'twilio-video'
import VideoParticipant from './VideoParticipant'

interface Props {
  room: Room
  stopCall: () => void
}

const VideoRoom = ({ room, stopCall }: Props) => {
  const [remoteParticipants, setRemoteParticipants] = useState<Participant[]>([])

  useEffect(() => {
    setRemoteParticipants(Array.from(room.participants.values()))

    room.on('participantConnected', (participant) => addParticipant(participant))
    room.on('participantDisconnected', (participant) => removeParticipant(participant))

    return () => {
      console.log('disconnecting from room')
      room.disconnect()
      stopCall()
    }
  }, []) //eslint-disable-line

  const addParticipant = (participant: Participant) => {
    console.log(`${participant.identity} has joined the room`)
    setRemoteParticipants((prevRemoteParticipants) => {
      return [...prevRemoteParticipants, participant]
    })
  }

  const removeParticipant = (participant: Participant) => {
    console.log(`${participant.identity} has left the room`)
    setRemoteParticipants((prevRemoteParticipants) => {
      return prevRemoteParticipants.filter((p) => p.identity !== participant.identity)
    })
  }

  return (
    <div className="room">
      <div className="participants relative">
        <VideoParticipant
          key={room.localParticipant.identity}
          localParticipant={true}
          participant={room.localParticipant}
        />

        {remoteParticipants.map((participant) => {
          return <VideoParticipant key={participant.identity} localParticipant={false} participant={participant} />
        })}
      </div>

      <IonFab slot="fixed" vertical="bottom" horizontal="center">
        <IonFabButton color="danger" onClick={stopCall}>
          <IonIcon icon={call} className="rotate-[135deg]" />
        </IonFabButton>
      </IonFab>
    </div>
  )
}

export default VideoRoom

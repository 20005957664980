import { IonDatetime, IonItem, IonLabel, IonPopover, IonText } from '@ionic/react'
import React from 'react'
import { capitalizeFirst } from '../../../core/helper/string.helper'

export interface SelectTimeFrameProps {
  title: string
  start: string
  end: string
  prevStart?: string
  handleChangeStart?: (start: string) => void
  handleChangeEnd?: (end: string) => void
  id: string
}

const SelectTimeFrame: React.FC<SelectTimeFrameProps> = (props: SelectTimeFrameProps) => {
  const hours: number[] = []

  if (props.prevStart) {
    const start = parseInt(props.prevStart.split(':')[0])
    const end = parseInt(props.end.split(':')[0])

    if (start <= end) {
      for (let i = start; i < end; i++) {
        hours.push(i)
      }
    } else {
      for (let i = start; i < 24; i++) {
        hours.push(i)
      }
      for (let i = 0; i < end; i++) {
        hours.push(i)
      }
    }
  }
  return (
    <IonItem>
      <IonLabel className="font-extrabold">{capitalizeFirst(props.title)}</IonLabel>
      <IonText id={`open-start-input-${props.id}`} slot="end">
        {props.start}
      </IonText>
      {props.handleChangeStart !== undefined && (
        <>
          <IonPopover trigger={`open-start-input-${props.id}`}>
            <IonDatetime
              presentation="time"
              hourCycle="h23"
              value={props.start}
              minuteValues="0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 40, 55"
              onIonChange={(event) => {
                props.handleChangeStart !== undefined && props.handleChangeStart(event.detail.value)
              }}
              {...(hours.length > 0 && { hourValues: hours })}
            />
          </IonPopover>
        </>
      )}

      <IonText id={`open-end-input-${props.id}`} slot="end">
        {props.end}
      </IonText>
      {props.handleChangeEnd !== undefined && (
        <IonPopover trigger={`open-end-input-${props.id}`}>
          <IonDatetime
            value={props.end}
            presentation="time"
            minuteValues="0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 40, 55"
            onIonChange={(event) => {
              props.handleChangeEnd !== undefined && props.handleChangeEnd(event.detail.value)
            }}
          />
        </IonPopover>
      )}
    </IonItem>
  )
}

export default SelectTimeFrame

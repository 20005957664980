import React from 'react'
import SettingsVolume from './SettingsVolume'
import SettingsLanguage from './SettingsLanguage'
import SettingsWiFi from './SettingsWiFi'
import SettingsAdmin from './SettingsAdmin'
import SettingsHeader from '../settings-header/SettingsHeader'
import { useVolume, useLanguage, useLogin } from '../../../hooks/settings/settingsHook'

const SettingsGeneral: React.FC = () => {
  return (
    <>
      <SettingsHeader title="General" color="iconAlgemeen" />
      <SettingsAdmin {...useLogin()} />
      <SettingsWiFi />
      <SettingsVolume {...useVolume()} />
      <SettingsLanguage {...useLanguage()} />
    </>
  )
}

export default SettingsGeneral

import React from 'react'
import { IonContent, IonPage } from '@ionic/react'
import SetupHeader from '../../components/setup/header/SetupHeader'
import useFirstTimeUse from '../../hooks/settings/useFirstTimeUse'
import { useHistory } from 'react-router'
import SetupButton from '../../components/setup/button/SetupButton'
import ReadyMessage from '../../components/setup/ready-message/SetupReady'
import SettingsButton from '../../components/home/SettingsButton'

const SetupReady: React.FC = () => {
  const { toggleFirstTimeUse } = useFirstTimeUse()
  const history = useHistory()

  const finishSetup = () => {
    finishFirstTimeUse()
    startClock()
  }

  const startClock = () => {
    history.push('/')
  }

  const finishFirstTimeUse = () => {
    toggleFirstTimeUse(false)
    localStorage.setItem('firstTimeUse', 'false')
  }

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="bg-blue-200 w-full h-full p-2 pt-10">
          <SetupHeader hideSteps={true} />
          <div className="rounded-md bg-white m-10 h-5/6 pt-10 pl-10 relative overflow-hidden dc7:m-5 dc7:pl-5 dc7:pt-5">
            <ReadyMessage />
            <SetupButton className="setup-button-next__position" onClick={() => finishSetup()}>
              KLAAR
            </SetupButton>
          </div>
        </div>
        <SettingsButton onClick={() => finishFirstTimeUse()} />
      </IonContent>
    </IonPage>
  )
}

export default SetupReady

import moment from 'moment'
import { Activity } from '../interfaces/activity'
import { DATEFORMAT_DATE, DATEFORMAT_DATE_TIME, getDuration, isInBetween, isSameMonth, isToday } from './date.helper'
import format from 'date-fns/format'
import { getDay, parse } from 'date-fns'
import { isAfter } from 'date-fns/fp'

const isActivityValid = (current: string, activity: Activity): boolean => {
  const c = moment(current, DATEFORMAT_DATE_TIME)
  const s = moment(activity.startDate, DATEFORMAT_DATE_TIME)
  const e = moment(activity.endDate, DATEFORMAT_DATE_TIME)
  const today = isToday(current, activity.startDate)

  let validDay = true

  if (activity.hasRepeatOnDays) {
    validDay = activity.repeatOnDays.includes(moment(c).format('d'))
  } else if (activity.hasRepeat) {
    validDay = checkForWeeks(current, activity)
  }

  return today && validDay && e.isSameOrAfter(s) && c.isSameOrAfter(s) && activity.clockType === undefined
}

const findCurrentActivity = (currentDate: string, activities: Activity[]): Activity | undefined => {
  const sortedActivities = [] as Activity[]
  const currentDateTime = moment().format(DATEFORMAT_DATE_TIME)
  activities.forEach((val) => sortedActivities.push(Object.assign({}, val)))
  const sActivities = sortedActivities.sort(
    (a, b) => getDuration(a.startDate, a.endDate) - getDuration(b.startDate, b.endDate),
  )

  const foundActivity = sActivities.find((activity) => {
    return isInBetween(currentDateTime, activity.startDate, activity.endDate)
  })

  let valid = true
  if (foundActivity) {
    valid = checkForRepeating(moment(currentDate, DATEFORMAT_DATE_TIME).toDate(), foundActivity)
  }

  return valid ? foundActivity : undefined
}

const checkForRepeating = (time: Date, activity: Activity): boolean => {
  let valid = true

  if (activity.repeatOnDays) {
    // Repeating on specific day
    // We need to add 1 because the API starts with 1 on Sunday, and in JS 0 is Sunday
    let currentDay = getDay(time)

    if (currentDay === 0) {
      currentDay = 1
    }

    if (!activity.repeatOnDays.includes('' + currentDay)) {
      valid = false
    }
  } else if (activity.hasRepeat || parseInt(activity.repeat) > 0) {
    valid = checkForWeeks(moment(time).format(DATEFORMAT_DATE_TIME), activity)
  }
  return valid
}

const checkForWeeks = (current: string, activity: Activity): boolean => {
  const c = moment(current)
  const start = moment(activity.startDate)

  const days = start.diff(c, 'days')
  const repeatInDays = +activity.repeat

  return repeatInDays !== 30
    ? days % repeatInDays === 0 && checkEndRepeat(current, activity.endRepeat)
    : isSameMonth(current, activity.startDate) && checkEndRepeat(current, activity.endRepeat)
}

const checkEndRepeat = (current: string, endRepeat: string): boolean => {
  if (endRepeat == null || '' === endRepeat || 'null' === endRepeat) {
    return true
  }
  return moment(current).isBefore(moment(endRepeat, DATEFORMAT_DATE_TIME).format(DATEFORMAT_DATE))
}

const findActivitiesByDate = (date: string, activities: Activity[]): Activity[] | undefined => {
  return activities.filter((activity) => activity.startDate.includes(date.substring(0, 10)))
}

const findActivitiesByDay = (date: Date, activities: Activity[]): Activity[] | undefined => {
  const dayOfWeek = format(date, 'i')
  return activities.filter((activity) =>
    activity.repeatOnDays
      ? activity.startDate.length === 5 && activity.repeatOnDays.includes(dayOfWeek)
      : activity.startDate.length === 5,
  )
}

const findActivitiesByDayAndTime = (current: Date, activities: Activity[]): Activity[] | undefined => {
  const dayOfWeek = format(current, 'i')

  const foundActivities = activities.filter((activity) => {
    const startTime = activity.startDate.includes(' ') ? activity.startDate.split(' ')[1] : activity.startDate
    const start = parse(startTime, 'HH:mm', current)

    return activity.repeatOnDays
      ? activity.startDate.length === 5 && activity.repeatOnDays.includes(dayOfWeek) && isAfter(start, current)
      : activity.startDate.length === 5 && isAfter(start, current)
  })

  return foundActivities
}

const activitiesHelper = {
  isActivityValid,
  findCurrentActivity,
  findActivitiesByDate,
  findActivitiesByDay,
  findActivitiesByDayAndTime,
}

export default activitiesHelper

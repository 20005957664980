import React from 'react'
import WeekDayHeader from './WeekDayHeader'
import WeekActivity from './WeekActivity'
import { Activity } from '../../store/activities/types'
import activitiesHelper from '../../core/helper/activities.helper'
import format from 'date-fns/format'

export interface PureWeekDayInterface {
  date: Date
  first?: boolean
  activities: Activity[]
}

const PureWeekDay: React.FC<PureWeekDayInterface> = (props: PureWeekDayInterface) => {
  const { date, activities } = props
  const activitiesPerDay = activitiesHelper.findActivitiesByDate(format(date, 'dd-MM-yyyy'), activities)
  const activitiesPerDayAndTime = activitiesHelper.findActivitiesByDay(date, activities)
  const filteredActivities = [...activitiesPerDay, ...activitiesPerDayAndTime]

  return (
    <div className="flex-1 border-gray border-r-2 bg-white">
      <WeekDayHeader date={date} />
      {[...filteredActivities].map((activity, i) => (
        <WeekActivity key={i} activity={activity} />
      ))}
    </div>
  )
}

const WeekDay: React.FC<PureWeekDayInterface> = (props: PureWeekDayInterface) => {
  const { date, activities } = props
  return <PureWeekDay date={date} activities={activities} />
}

export default WeekDay

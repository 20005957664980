import React from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'

export interface HeaderIconProps {
  /** The label for the icon */
  label: string
  /** The route to link to */
  to: string
  /** Status of the menu item */
  active?: boolean
}

const HeaderIcon: React.FC<HeaderIconProps> = (props: HeaderIconProps) => {
  const { label, to, active } = props

  return (
    <Link
      to={to}
      className={clsx(
        'relative w-10 h-10 rounded-full border-solid border-2 border-blue-500 text-center flex items-center justify-center no-underline font-bold',
        active && 'bg-blue-500 text-white',
        !active && 'text-blue-500',
      )}
    >
      {label}
    </Link>
  )
}
HeaderIcon.defaultProps = {
  active: false,
}

export default HeaderIcon

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPhoto, PhotoState } from '../../store/photo/photoSlice'
import { Photo } from '../../store/settings/types'
import { RootState } from '../../store/store'

export interface ActivityPhotoInterface {
  photo: Photo
}
const useActivityPhoto = (key: string): ActivityPhotoInterface => {
  const dispatch = useDispatch()
  const [photo, setPhoto] = useState<Photo>()
  const [cache, setCache] = useState<Map<string, string>>(new Map<string, string>()) // eslint-disable-line
  const storedPhoto: PhotoState = useSelector((state: RootState) => state.photo)

  useEffect(() => {
    setPhoto(storedPhoto.photo)
    if (storedPhoto.photo) {
      cache.set(key, storedPhoto.photo.pictureData)
    }
  }, [cache, key, storedPhoto.photo])

  useEffect(() => {
    if (typeof key === 'undefined') return

    if (cache.has(key)) {
      const photo = {
        pictureData: cache.get(key),
      } as Photo
      setPhoto(photo)
    } else {
      dispatch(getPhoto({ key }))
    }
  }, [cache, dispatch, key])

  return { photo: photo }
}

export default useActivityPhoto

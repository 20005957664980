import { useEffect, useRef } from 'react'
import { getDatabase, ref, set } from 'firebase/database'
import useLogin from '../settings/useLogin'
import { FIREBASE_KEY } from '../../core/const'

const useHeartBeatFirebase = (): void => {
  const fbDatabase = getDatabase()
  const { user } = useLogin()
  const timer = useRef(null)
  const INTERVAL = 15 * 60 * 1000 // 15 minutes

  useEffect(() => {
    const sendHeartBeat = (user: string | undefined) => {
      if (user) {
        set(ref(fbDatabase, `/${FIREBASE_KEY}/heartbeat/${user}`), {
          lastBeat: Date.now(),
        })
      }
    }

    sendHeartBeat(user)

    timer.current = setInterval(() => {
      sendHeartBeat(user)
    }, INTERVAL)
    return () => {
      clearInterval(timer.current)
    }
  }, [user]) // eslint-disable-line react-hooks/exhaustive-deps
}

export default useHeartBeatFirebase

import { Action, ActionCreator } from 'redux'
import { SET_DAYPART_START } from '../types'

export const updateDayPartStart: ActionCreator<Action> = (key: string, time: string) => {
  return {
    type: SET_DAYPART_START,
    payload: {
      key: key,
      time: time,
    },
  }
}

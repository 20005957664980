import { OpenNativeSettings } from '@ionic-native/open-native-settings'
import { IonButton, IonItem, IonLabel, IonList, IonListHeader, IonText } from '@ionic/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNetwork } from '../../../hooks/network/useNetwork'

const SettingsWiFi: React.FC = () => {
  const { t } = useTranslation()
  const connected = useNetwork()

  const openNativeWifiSettings = async () => {
    await OpenNativeSettings.open('wifi')
  }

  return (
    <>
      <IonListHeader>
        <IonLabel className="text-xl dc7:text-lg font-bold">{t('settings.wifi.title')}</IonLabel>
      </IonListHeader>
      <IonList lines="full">
        <IonItem className="dc7:text-sm">
          <IonButton slot="end" onClick={() => openNativeWifiSettings()}>
            {t('settings.wifi.button')}
          </IonButton>
          <IonText>{connected ? t('settings.wifi.status.connected') : t('settings.wifi.status.connect')}</IonText>
        </IonItem>
      </IonList>
    </>
  )
}

export default SettingsWiFi

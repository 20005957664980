import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useWeatherForecast = (): any => {
  const { weather } = useSelector((state: RootState) => state.weather)
  const { temperature, position } = useSelector((state: RootState) => state.settings.weather)
  return { weather, temperature, position }
}

export default useWeatherForecast

import axios, { AxiosRequestConfig, AxiosPromise } from 'axios'

const REACT_APP_USER_API = 'https://api.dayclocks.nl/api/v1/'

const instance = axios.create({
  baseURL: REACT_APP_USER_API,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const request = (options: AxiosRequestConfig): AxiosPromise<any> => {
  return instance(options)
}

export default request

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { SettingsLanguage } from '../../core/interfaces/settings'
import { setLanguage } from '../../store/settings/actions'
import { SettingsStateI } from '../../store/settings/types'
import { RootState } from '../../store/store'

const useLanguage = (): SettingsLanguage => {
  const { i18n } = useTranslation()

  const { language } = useSelector((state: RootState) => state.settings) as SettingsStateI

  const dispatch = useDispatch()

  const handleChange = (code: string): void => {
    dispatch(setLanguage(code))
    i18n.changeLanguage(code)
  }
  return { language, handleChange }
}

export default useLanguage

import { useEffect, useState } from 'react'

interface UseDateIntervalInterface {
  date: Date
}

const useDateInterval = (interval = 1): UseDateIntervalInterface => {
  const [date, setDate] = useState(new Date())

  useEffect(() => {
    const timerId = setInterval(() => tick(), interval * 1000)
    return function cleanup() {
      clearInterval(timerId)
    }
  }, [interval])

  function tick() {
    setDate(new Date())
  }
  return { date }
}

export default useDateInterval

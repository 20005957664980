export const CURRENT_ACTIVITY_LOAD = 'CURRENT_ACTIVITY_LOAD'
export const CURRENT_ACTIVITY_LOAD_SUCCESS = 'CURRENT_ACTIVITY_LOAD_SUCCESS'
export const ACTIVITIES_LOAD = 'ACTIVITIES_LOAD'
export const ACTIVITIES_LOAD_SUCCESS = 'ACTIVITIES_LOAD_SUCCESS'
export const ALBUM_LOAD = 'ALBUM_LOAD'
export const ALBUM_LOAD_SUCCESS = 'ALBUM_LOAD_SUCCESS'

export interface Activity {
  loading?: boolean
  startDate: string
  endDate: string
  key?: string
  title: string
  textColor?: string
  backgroundColorFrom?: string
  backgroundColorTo?: string
  pictureUrl?: string
  repeatOnDays?: string
  hasRepeat?: boolean
  repeat?: string
  endRepeat?: string
  album?: string
  hasSpeech?: boolean
  hasSound?: boolean
  hasVoice?: boolean
  hasRepeatOnDays?: boolean
  speechInterval?: string
  speechMaxRepeat?: string
  pictureData?: string
  creationDate?: string
  clockType?: string
  videoUrl?: string
  videoDuration?: number
  answers?: string
  voiceUrl?: string
  spotifyUrl?: string
  youtubeId?: string
}

export interface ActivitiesState {
  currentActivity: Activity
  activities?: Activity[]
}

import { Action, ActionCreator } from 'redux'
import { SET_SLEEP_END, SET_SLEEP_START, TOGGLE_SLEEP_MODUS } from '../types'

export const toggleSleepModusSetting: ActionCreator<Action> = (isChecked: boolean) => {
  return {
    type: TOGGLE_SLEEP_MODUS,
    payload: isChecked,
  }
}

export const updateSleepStart: ActionCreator<Action> = (time: string) => {
  return {
    type: SET_SLEEP_START,
    payload: time,
  }
}

export const updateSleepEnd: ActionCreator<Action> = (time: string) => {
  return {
    type: SET_SLEEP_END,
    payload: time,
  }
}

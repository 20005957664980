import { IonIcon } from '@ionic/react'
import React from 'react'
import { timeOutline } from 'ionicons/icons'
import { Activity } from '../../core/interfaces/activity'
import { formatColor } from '../../core/helper/string.helper'
import { showDateAsTime } from '../../core/helper/date.helper'
import './DayActivity.css'
import Photo from '../photo/Photo'

export interface DayActivityProps {
  activity: Activity
}

const DayActivity: React.FC<DayActivityProps> = (props: DayActivityProps) => {
  const { activity } = props

  const time = `${showDateAsTime(activity.startDate)} - ${showDateAsTime(activity.endDate)}`
  const style = {
    color: formatColor(activity.textColor),
    backgroundColor: formatColor(activity.backgroundColorFrom),
  }
  return (
    <>
      <div className="rounded-md text-3xl h-28 p-2 mx-5 mb-2 flex" style={style}>
        <div className="w-full">
          <div className="font-semibold">{activity.title.substring(0, 100)}</div>
          <div className="mt-2 text-xl">
            <IonIcon icon={timeOutline} className="mr-2" />
            {time}
          </div>
        </div>

        <div className="justify-items-end ml-10">
          <Photo
            pictureData={activity.pictureData}
            pictureUrl={activity.pictureUrl}
            stylePicture={'image-day-activity'}
          />
        </div>
      </div>
    </>
  )
}

export default DayActivity

import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { Activity } from '../../store/activities/types'

interface ActivitiesInterface {
  activities: Array<Activity>
}

const useActivities = (): ActivitiesInterface => {
  const activities = useSelector((state: RootState) => state.allActivities.activities)

  return { activities }
}

export default useActivities

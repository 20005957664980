import { IonInput, IonItem, IonLabel } from '@ionic/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import SetupButton from '../button/SetupButton'
import { Login as LoginInterface } from '../../../core/interfaces/settings'

import lock from './lock.svg'

const AccountSetup: React.FC<LoginInterface> = ({
  surname,
  username,
  password,
  isError,
  setUsername,
  setPassword,
  handleLogin,
}: LoginInterface) => {
  const { t } = useTranslation()

  return (
    <div className="account-setup h-5/6 relative">
      <div className="ml-12 mt-10 w-2/3 dc7:mt-5">
        {surname ? (
          <div className="text-4xl font-sans mb-10">{t('setup.account.welcome', { surname: surname })}</div>
        ) : (
          <>
            <div className="text-4xl font-sans mb-2">{t('setup.account.title')}</div>
            <div className="text-lg font-sans mb-2">{t('setup.account.subtitle')}</div>
            <div className="rounded border-2 p-5 pr-10 mr-10 dc7:p-3 dc7:pr-5 dc7:mr-5">
              {isError && (
                <div className="flex items-center bg-red-500 text-white text-sm font-bold px-4 py-3" role="alert">
                  <p>{t('settings.menu.general.invalid_credential')}</p>
                </div>
              )}

              <IonItem>
                <IonLabel position="floating">{t('setup.account.label.username')}</IonLabel>
                <IonInput
                  type="text"
                  value={username}
                  onIonChange={(event) => setUsername(event.detail.value)}
                ></IonInput>
              </IonItem>

              <IonItem>
                <IonLabel position="floating">{t('setup.account.label.password')}</IonLabel>
                <IonInput
                  type="password"
                  value={password}
                  onIonChange={(event) => setPassword(event.detail.value)}
                ></IonInput>
              </IonItem>

              <SetupButton
                className="mt-10 mb-5 ml-3 dc7:mt-5"
                onClick={handleLogin}
                showAfter={false}
                disabled={!(username && password)}
              >
                {t('setup.account.button.login')}
              </SetupButton>
            </div>
          </>
        )}
      </div>
      <div className="absolute right-10 top-0 w-56 dc7:w-48">
        <img src={lock} alt="Lock" />
      </div>
    </div>
  )
}

export default AccountSetup

import { IonList } from '@ionic/react'
import React from 'react'
import useLanguage from '../../../hooks/settings/useLanguage'
import { DayPartKeys, Language } from '../../../store/settings/types'
import SettingsHeader from '../settings-header/SettingsHeader'
import SettingsSleepModus from '../settings-sleep-modus/SettingsSleepModus'
import useSleepModus from '../../../hooks/settings/useSleepModus'
import SelectTimeFrame from './SelectTimeFrame'
import { useTranslation } from 'react-i18next'
import useDayParts, { DayPartsHook } from '../../../hooks/settings/useDayParts'

const SettingsDayparts: React.FC = () => {
  const { t } = useTranslation()
  const { language } = useLanguage()
  const sleepModus = useSleepModus()

  const dayParts: DayPartsHook[] = [
    useDayParts(DayPartKeys.Morning),
    useDayParts(DayPartKeys.PreAfternoon),
    useDayParts(DayPartKeys.Afternoon),
    useDayParts(DayPartKeys.PostAfternoon),
    useDayParts(DayPartKeys.Evening),
    useDayParts(DayPartKeys.Night),
  ]

  // remove the dayParts only usable for German
  if (language !== Language.german) {
    dayParts.splice(1, 1)
    dayParts.splice(2, 1)
  }

  return (
    // todo implement recalculation of the dayparts after timechange
    <>
      <SettingsHeader title="Dayparts" color="iconDayparts" />
      <IonList className="w-full">
        {dayParts.map((dayPart: DayPartsHook) => {
          const currentDayPartIndex = dayParts.indexOf(dayPart)
          let previousDayPartIndex = currentDayPartIndex - 1
          if (previousDayPartIndex < 0) {
            previousDayPartIndex = dayParts.length - 1
          }

          const previousDayPart = dayParts[previousDayPartIndex]

          return (
            <SelectTimeFrame
              key={dayPart.key}
              title={t(`settings.menu.dayparts.${dayPart.key}`)}
              start={dayPart.start}
              end={dayPart.end}
              prevStart={previousDayPart.start}
              handleChangeStart={dayPart.setDayPartStart}
              id={dayPart.key}
            />
          )
        })}
      </IonList>

      <IonList className="w-full">
        <SettingsSleepModus {...sleepModus} />
        <SelectTimeFrame
          title={t(`settings.menu.dayparts.dim`)}
          start={sleepModus.start}
          end={sleepModus.end}
          handleChangeStart={sleepModus.setSleepStart}
          handleChangeEnd={sleepModus.setSleepEnd}
          id="sleep"
        />
      </IonList>
    </>
  )
}

export default SettingsDayparts

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ACTIVITIES_LOAD, Activity } from './types'

import { getActivities as getActivitiesService } from '../../services/api/ActivitiesApi'
import { logout } from '../user/userSlice'
import { RootState } from '../store'
import { format } from 'date-fns'

export interface ActivitiesState {
  isError: boolean
  activities: Array<Activity>
}

const initialState: ActivitiesState = {
  isError: false,
  activities: [],
}

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

export const getActivities = createAsyncThunk(ACTIVITIES_LOAD, async (_, thunkAPI) => {
  const state = thunkAPI.getState() as RootState

  const user = state.user.user
  const currentMonth = format(new Date(), 'yyyyMM')

  await sleep(3000)

  const { data } = await getActivitiesService(user, currentMonth)

  return data
})

export const activitiesSlice = createSlice({
  name: 'activities',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getActivities.fulfilled, (state, { payload }) => {
      return {
        ...state,
        isError: false,
        activities: payload,
      }
    })
    builder.addCase(getActivities.rejected, (state) => {
      state.isError = true
    })
    builder.addCase(logout.fulfilled, () => {
      return initialState
    })
  },
})

export default activitiesSlice.reducer

import { IonItem, IonLabel, IonList, IonListHeader, IonSelect, IonSelectOption } from '@ionic/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { DateFormat, TimeFormat, Color } from '../../../store/settings/types'
import SettingsHeader from '../settings-header/SettingsHeader'
import SettingsToggleItem from '../settings-items/SettingsToggleItem'
import useClockType from '../../../hooks/settings/clockType'
import ClockType from './ClockType'
import useColor from '../../../hooks/settings/useColor'
import moment from 'moment'
import useLanguage from '../../../hooks/settings/useLanguage'
import SettingsColorPicker from '../settings-items/SettingsColorPicker'
import useClock from '../../../hooks/settings/useClock'

const SettingsClock: React.FC = () => {
  const { t } = useTranslation()
  const { language } = useLanguage()
  const { dateFormat, timeFormat, showSeconds, toggleSeconds, setDateFormat, setTimeFormat } = useClock()

  return (
    <>
      <SettingsHeader title="Clock" color="iconClock" />

      <IonListHeader>
        <IonLabel className="text-xl dc7:text-lg font-bold">{t('Show')}</IonLabel>
      </IonListHeader>

      <IonList lines="full">
        <IonItem className="dc7:text-sm">
          <IonLabel>{t('Date format')}</IonLabel>
          <IonSelect cancelText={t('Cancel')} value={dateFormat} onIonChange={(e) => setDateFormat(e.detail.value)}>
            <IonSelectOption value={DateFormat.DayMonthFull}>
              {moment(new Date()).locale(language).format(DateFormat.DayMonthFull)}
            </IonSelectOption>
            <IonSelectOption value={DateFormat.DayMonthYearFull}>
              {moment(new Date()).locale(language).format(DateFormat.DayMonthYearFull)}
            </IonSelectOption>
            <IonSelectOption value={DateFormat.DayMonthDotFull}>
              {moment(new Date()).locale(language).format(DateFormat.DayMonthDotFull)}
            </IonSelectOption>
            <IonSelectOption value={DateFormat.DayMonthDotYearFull}>
              {moment(new Date()).locale(language).format(DateFormat.DayMonthDotYearFull)}
            </IonSelectOption>
            <IonSelectOption value={DateFormat.DayMonthYearShort}>
              {moment(new Date()).locale(language).format(DateFormat.DayMonthYearShort)}
            </IonSelectOption>
            <IonSelectOption value={DateFormat.MonthDayYearShort}>
              {moment(new Date()).locale(language).format(DateFormat.MonthDayYearShort)}
            </IonSelectOption>
          </IonSelect>
        </IonItem>

        <IonItem className="dc7:text-sm">
          <IonLabel>{t('Time format')}</IonLabel>
          <IonSelect value={timeFormat} onIonChange={(e) => setTimeFormat(e.detail.value)}>
            <IonSelectOption value={TimeFormat.H12}>{moment(new Date()).format('h:mm a')}</IonSelectOption>
            <IonSelectOption value={TimeFormat.H24}>{moment(new Date()).format('HH:mm')}</IonSelectOption>
          </IonSelect>
        </IonItem>

        <IonItem className="dc7:text-sm">
          <ClockType {...useClockType()} />
        </IonItem>

        <SettingsToggleItem
          label={t('settings.menu.clock.seconds')}
          sublabel={t('settings.menu.clock.seconds_help')}
          onChange={toggleSeconds}
          checked={showSeconds}
        />
      </IonList>

      <IonListHeader>
        <IonLabel className="text-xl dc7:text-lg font-bold">{t('Colors')}</IonLabel>
      </IonListHeader>

      <IonList lines="full">
        <SettingsColorPicker
          title={t('settings.menu.clock.colors.text.title')}
          modalTitle={t('settings.menu.clock.colors.text.change_title')}
          {...useColor(Color.Text)}
        />

        <SettingsColorPicker
          title={t('settings.menu.clock.colors.background.title')}
          modalTitle={t('settings.menu.clock.colors.background.change_title')}
          {...useColor(Color.Background)}
        />
      </IonList>
    </>
  )
}

export default SettingsClock

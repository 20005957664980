import React from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

interface Props {
  className?: string
}

const ReadyMessage: React.FC<Props> = (props: Props) => {
  const { className } = props
  const { t } = useTranslation()

  return (
    <div className={clsx('mt-24 m-12 dc7:mt-12 dc7:m-5 ', className)}>
      <div className="text-4xl font-sans mb-10">{t('setup.ready.title')}</div>
      <div>{t('setup.ready.description')}</div>
    </div>
  )
}

export default ReadyMessage

import moment from 'moment'

export const DATEFORMAT_DATE = 'DD-MM-YYYY'
export const DATEFORMAT_DATE_TIME = 'DD-MM-YYYY HH:mm'
export const DATEFORMAT_TIME = 'HH:mm'
export const DATEFORMAT_MONTH = 'MM'

export const parseDate = (date: string): string => {
  date = date.trim()
  const currentDate = moment().format(DATEFORMAT_DATE)

  if (date.length === 5) {
    date = `${currentDate} ${date}`
  }
  return date
}

export const isToday = (current: string, date: string): boolean => {
  const currentDay = moment(current, DATEFORMAT_DATE_TIME).format(DATEFORMAT_DATE)
  const dateDay = moment(date, DATEFORMAT_DATE_TIME).format(DATEFORMAT_DATE)
  return currentDay === dateDay
}

export const isSameMonth = (date1: string, date2: string): boolean => {
  const d1 = moment(date1, DATEFORMAT_DATE_TIME).format(DATEFORMAT_MONTH)
  const d2 = moment(date2, DATEFORMAT_DATE_TIME).format(DATEFORMAT_MONTH)
  return d1 === d2
}

export const isInBetween = (current: string, start: string, end: string): boolean => {
  const c = moment(current, DATEFORMAT_DATE_TIME)
  const s = moment(start, DATEFORMAT_DATE_TIME)
  const e = moment(end, DATEFORMAT_DATE_TIME)

  return c.isSameOrAfter(s) && c.isBefore(e)
}

export const convertTimeToDate = (time: string): string => {
  const date = new Date()
  date.setHours(+time.split(':')[0])
  date.setMinutes(+time.split(':')[1])
  return moment(date).format('YYYY-MM-DD HH:mm')
}

export const getDuration = (start: string, end: string): number => {
  const s = moment(start, DATEFORMAT_DATE_TIME)
  const e = moment(end, DATEFORMAT_DATE_TIME)

  return e.diff(s, 'seconds')
}

export const showDateAsTime = (date: string): string => {
  return date.length > 5 ? date.substring(11, 16) : date
}

export const URL_SERVER = 'https://testdayclocks.herokuapp.com/'

export const WeatherTimer: Array<string> = ['08:00', '10:00', '12:00', '14:00', '16:00', '18:00', '20:00']
export const VERSION = '2023.02.6'

export const FIREBASE_KEY = 'QWR23431TWV'

export const firebaseConfig = {
  apiKey: 'AIzaSyDgGpLDWLcRK38xRaYVVca3hDJTCeiFoRA',
  authDomain: 'dayclocks-762a4.firebaseapp.com',
  databaseURL: 'https://dayclocks-762a4.firebaseio.com',
  projectId: 'dayclocks-762a4',
  storageBucket: 'dayclocks-762a4.appspot.com',
  messagingSenderId: '132112169168',
  appId: '1:132112169168:web:239a0f7ba89271f96b86d5',
}

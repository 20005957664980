import { IonButton, IonButtons, IonHeader, IonIcon, IonItem, IonLabel, IonModal, IonToolbar } from '@ionic/react'
import { checkmarkCircleOutline, closeOutline } from 'ionicons/icons'

import React, { useRef } from 'react'
import invertColor from '../../../core/helper/color.helper'
import { Color } from '../../../store/settings/types'

const availableColors = [
  '#f70001',
  '#f44336',
  '#e91e63',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#1200f7',
  '#2196f3',
  '#03a9f4',
  '#00bcd4',
  '#04f606',
  '#009688',
  '#4caf50',
  '#8bc34a',
  '#cddc39',
  '#ffeb3b',
  '#ffc107',
  '#ff9800',
  '#ff5722',
  '#795548',
  '#607d8b',
  '#000000',
  '#ffffff',
]

export interface SettingsColorPickerProps {
  type: Color
  title: string
  modalTitle: string
  color: string
  setColor: (color: string) => void
}

const SettingsColorPicker: React.FC<SettingsColorPickerProps> = ({
  type,
  title,
  modalTitle,
  color,
  setColor,
}: SettingsColorPickerProps) => {
  const modal = useRef<HTMLIonModalElement>(null)

  return (
    <>
      <IonItem id={`open-modal-${type}`} className="dc7:text-sm">
        <IonLabel>{title}</IonLabel>
        <IonButton
          slot="end"
          fill="clear"
          className="w-6 h-6"
          style={{ backgroundColor: color, border: '2px solid black' }}
        ></IonButton>
      </IonItem>
      <IonModal ref={modal} trigger={`open-modal-${type}`}>
        <IonHeader className="p-3 pl-5 pr-0">
          <IonToolbar>
            <h2>{modalTitle}</h2>
            <IonButtons slot="end">
              <IonButton color="dark" onClick={() => modal.current?.dismiss()}>
                <IonIcon icon={closeOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <div className="flex flex-wrap space-around justify-center pt-5">
          {availableColors.map((availableColor) => {
            return (
              <IonButton
                key={availableColor}
                style={{ '--background': availableColor }}
                className="w-20 h-20 m-2"
                shape="round"
                onClick={() => {
                  setColor(availableColor)
                  modal.current?.dismiss()
                }}
              >
                {availableColor === color && (
                  <IonIcon
                    icon={checkmarkCircleOutline}
                    className="w-10 h-10"
                    style={{ color: invertColor(availableColor) }}
                  />
                )}
              </IonButton>
            )
          })}
        </div>
      </IonModal>
    </>
  )
}

export default SettingsColorPicker

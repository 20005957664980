import { useDispatch, useSelector } from 'react-redux'
import { TextColorPicker } from '../../core/interfaces/settings'
import { setBackground, setTextColor } from '../../store/settings/actions'
import { Color } from '../../store/settings/types'
import { RootState } from '../../store/store'

const useColor = (key: Color): TextColorPicker => {
  const dispatch = useDispatch()

  const baseColor = useSelector((state: RootState) => state.settings.colors[key])

  const setColor = (color: string) => {
    if (key === Color.Text) {
      dispatch(setTextColor(color))
    } else if (key === Color.Background) {
      dispatch(setBackground(color))
    }
  }

  return {
    type: key,
    color: baseColor,
    setColor,
  }
}

export default useColor

import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Login } from '../../core/interfaces/settings'
import { RootState } from '../../store/store'
import { login, logout } from '../../store/user/userSlice'

const useLogin = (): Login => {
  const dispatch = useDispatch()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const { isError, loginSucess, surname, token, user } = useSelector((state: RootState) => state.user)

  const handleLogin = (): void => {
    dispatch(
      login({
        username,
        password,
      }),
    )
  }

  const handleLogout = (): void => {
    setUsername('')
    setPassword('')
    dispatch(logout())
  }

  return {
    surname,
    username,
    user,
    setUsername,
    handleLogin,
    handleLogout,
    isError,
    loginSucess,
    password,
    token,
    setPassword,
  }
}

export default useLogin

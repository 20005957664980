import { AxiosPromise } from 'axios'
import { URL_SERVER } from '../../core/const'
import { AlbumRequest } from '../../core/interfaces/request'
import request from './BaseApi'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getAlbum = (req: AlbumRequest): AxiosPromise<any> => {
  const { token, albumId } = req
  return request({
    url: `${URL_SERVER}api/v2/album/${albumId}`,
    method: 'GET',
    headers: {
      Token: token,
    },
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getPhotos = (req: AlbumRequest): AxiosPromise<any> => {
  const { token, albumId } = req
  return request({
    url: `${URL_SERVER}api/v2/photo/album/${albumId}`,
    method: 'GET',
    headers: {
      Token: token,
    },
  })
}

const albumApi = {
  getAlbum,
  getPhotos,
}

export default albumApi

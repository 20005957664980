import { useDispatch, useSelector } from 'react-redux'
import { SettingsFirstTimeUse } from '../../core/interfaces/settings'
import { toggleFirstTimeUseSetting } from '../../store/settings/actions'
import { SettingsStateI } from '../../store/settings/types'
import { RootState } from '../../store/store'

const useFirstTimeUse = (): SettingsFirstTimeUse => {
  const { firstTimeUse } = useSelector((state: RootState) => state.settings) as SettingsStateI

  const dispatch = useDispatch()

  const toggleFirstTimeUse = (isFirstTimeUse: boolean): void => {
    dispatch(toggleFirstTimeUseSetting(isFirstTimeUse))
  }
  return { firstTimeUse, toggleFirstTimeUse }
}

export default useFirstTimeUse

import moment from 'moment'
import { useSelector } from 'react-redux'
import { getDayPartsForLanguage } from '../../core/helper/dayparts.helper'
import { DayPart } from '../../core/interfaces/DayPart'
import { SettingsStateI } from '../../store/settings/types'
import { RootState } from '../../store/store'
import useDateInterval from '../interval/useDateInterval'

const getDayPart = (date: Date, dayParts: DayPart, language: string): string => {
  const dayPartKeys = getDayPartsForLanguage(language)
  let currentDayPart: string

  dayPartKeys.forEach((item) => {
    const start = moment(dayParts[item].start, 'hh:mm')
    const end = moment(dayParts[item].end, 'hh:mm')

    if (start.diff(end) > 0) {
      start.subtract('1', 'days')
    }

    if (moment(date).isBetween(start, end)) {
      currentDayPart = item
    }
  })

  return currentDayPart
}

const useCurrentDayPart = (): string => {
  const { dayParts, language } = useSelector((state: RootState) => state.settings) as SettingsStateI

  const { date } = useDateInterval(60)

  return getDayPart(date, dayParts, language)
}

export default useCurrentDayPart

import { AxiosPromise } from 'axios'
import { URL_SERVER } from '../../core/const'
import { PhotoRequest } from '../../core/interfaces/request'
import request from './BaseApi'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getPhoto = (req: PhotoRequest): AxiosPromise<any> => {
  const { key } = req
  return request({
    url: `${URL_SERVER}api/v2/activities/activity/photo/${key}`,
    method: 'GET',
  })
}

const photoApi = {
  getPhoto,
}

export default photoApi

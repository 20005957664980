/* eslint-disable @typescript-eslint/no-explicit-any */
import { IonLabel, IonSelect, IonSelectOption } from '@ionic/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ClockTypeEnum } from '../../../store/settings/types'

export interface ClockTypeInterface {
  type: ClockTypeEnum
  setType: any
}

const ClockType: React.FC<ClockTypeInterface> = ({ type, setType }: ClockTypeInterface) => {
  const { t } = useTranslation()

  return (
    <>
      <IonLabel>{t('Clock')}</IonLabel>
      <IonSelect
        value={type}
        cancelText={t('Cancel')}
        onIonChange={(e) => {
          setType(e.detail.value)
        }}
      >
        <IonSelectOption value={ClockTypeEnum.Analog}>{t('settings.menu.clock.analog')}</IonSelectOption>
        <IonSelectOption value={ClockTypeEnum.Digital}>{t('settings.menu.clock.digital')}</IonSelectOption>
        <IonSelectOption value={ClockTypeEnum.DigitalAndAnalog}>
          {t('settings.menu.clock.digital_and_analog')}
        </IonSelectOption>
        <IonSelectOption value={ClockTypeEnum.Day}>{t('settings.menu.clock.day')}</IonSelectOption>
        <IonSelectOption value={ClockTypeEnum.Week}>{t('settings.menu.clock.week')}</IonSelectOption>
      </IonSelect>
    </>
  )
}

export default ClockType

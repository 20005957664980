import { Database, ref, onValue, off } from 'firebase/database'
import { useEffect, useState } from 'react'
import { FIREBASE_KEY } from '../../core/const'
import { UpdateFirebase } from '../../core/interfaces/firebase'
import { useLogin } from '../settings/settingsHook'

export const useUpdatesFirebase = (database: Database): UpdateFirebase => {
  const { user } = useLogin()
  const [result, setResult] = useState<UpdateFirebase>()

  useEffect(() => {
    const updatesCallRef = ref(database, `${FIREBASE_KEY}/updates/${user}`)
    if (user) {
      onValue(updatesCallRef, (snapshot) => {
        const data = snapshot.val()
        setResult(data)
      })
    }
    return () => {
      if (user) {
        off(updatesCallRef)
      }
    }
  }, [user]) // eslint-disable-line react-hooks/exhaustive-deps

  return result
}

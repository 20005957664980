import React, { useContext } from 'react'
import { IonIcon } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import SettingsMenuItem from './SettingsMenuItem'
import { arrowBackCircleOutline } from 'ionicons/icons'
import useLanguage from '../../../hooks/settings/useLanguage'
import { Language } from '../../../store/settings/types'
import SettingType from '../../../core/enums/SettingsType'
import settingsMenuContext from '../../../core/context/settingsMenuContext'

const SettingsMenu: React.FC = () => {
  const { t } = useTranslation()
  const { language } = useLanguage()
  const { selectedSetting } = useContext(settingsMenuContext)

  return (
    <div className="bg-blue-100 flex flex-col h-full justity-between">
      <div className="flex-none p-5">
        <Link to="/home">
          <IonIcon className="text-4xl" icon={arrowBackCircleOutline}></IonIcon>
        </Link>
      </div>
      <div className="flex-auto flex flex-col">
        <SettingsMenuItem
          title={t('settings.menu.general.title')}
          description={t('settings.menu.general.description')}
          type={SettingType.General}
          active={SettingType.General === selectedSetting}
        />
        <SettingsMenuItem
          title={t('settings.menu.clock.title')}
          description={t('settings.menu.clock.description')}
          type={SettingType.Clock}
          active={SettingType.Clock === selectedSetting}
        />
        <SettingsMenuItem
          title={t('settings.menu.dayparts.title')}
          description={t('settings.menu.dayparts.description')}
          type={SettingType.DayParts}
          active={SettingType.DayParts === selectedSetting}
        />
        <SettingsMenuItem
          title={t('settings.menu.weather.title')}
          description={t('settings.menu.weather.description')}
          type={SettingType.Weather}
          active={SettingType.Weather === selectedSetting}
        />
        {(language === Language.dutch || language === Language.english || language === Language.german) && (
          <SettingsMenuItem
            title={t('settings.menu.help.title')}
            description={t('settings.menu.help.description')}
            type={SettingType.Help}
            active={SettingType.Help === selectedSetting}
          />
        )}
      </div>
    </div>
  )
}

export default SettingsMenu

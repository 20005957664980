import {
  ClockTypeEnum,
  DateFormat,
  SettingsStateI,
  TimeFormat,
  SET_DATE_FORMAT,
  SET_TIME_FORMAT,
  TOGGLE_SHOW_DAYPART,
  TOGGLE_SHOW_SECONDS,
  TOGGLE_SHOW_MINUTES,
  SET_VOLUME,
  TOGGLE_SHOW_MENU,
  SET_LANGUAGE,
  Theme,
  SET_THEME,
  SET_FIRST_TIME_USE,
  Temperature,
  SET_WEATHER_COUNTRY,
  SET_WEATHER_CITY,
  SET_WEATHER_TEMPERATURE,
  Countries,
  SET_CLOCK_TYPE,
  SET_COLOR,
  SET_BACKGROUND,
  Language,
  SET_INITIAL_SETTTING,
  SET_WEATHER_POSITION,
  TOGGLE_SLEEP_MODUS,
  SET_SLEEP_START,
  SET_SLEEP_END,
  DayPartKeys,
  SET_DAYPART_START,
} from './types'
import { AnyAction } from 'redux'
import produce from 'immer' //eslint-disable-line

import { Position } from '../../core/enums/position'
import { getDefaultSleepTimeFrame } from '../../services/sleep-service'
import dayPartService from '../../services/daypart-service'
import { getDayPartsForLanguage } from '../../core/helper/dayparts.helper'
import { DayPart } from '../../core/interfaces/DayPart'

export const initialDayParts: DayPart = {
  [DayPartKeys.Morning]: dayPartService.getDefaultDayPartTimeFrame(DayPartKeys.Morning),
  [DayPartKeys.PreAfternoon]: dayPartService.getDefaultDayPartTimeFrame(DayPartKeys.PreAfternoon),
  [DayPartKeys.Afternoon]: dayPartService.getDefaultDayPartTimeFrame(DayPartKeys.Afternoon),
  [DayPartKeys.PostAfternoon]: dayPartService.getDefaultDayPartTimeFrame(DayPartKeys.PostAfternoon),
  [DayPartKeys.Evening]: dayPartService.getDefaultDayPartTimeFrame(DayPartKeys.Evening),
  [DayPartKeys.Night]: dayPartService.getDefaultDayPartTimeFrame(DayPartKeys.Night),
}

export const initialState: SettingsStateI = {
  language: Language.english,
  showMenu: true,
  sleepModus: {
    enabled: false,
    ...getDefaultSleepTimeFrame(),
  },
  volume: 100,
  theme: Theme.Normal,
  clock: {
    type: ClockTypeEnum.Analog,
    showDayPart: true,
    showSeconds: true,
    showMinutes: true,
  },
  colors: {
    textColor: '#ffffff',
    backgroundColor: '#000000',
  },
  dateFormat: DateFormat.DayMonthFull,
  timeFormat: TimeFormat.H24,
  dayParts: initialDayParts,
  firstTimeUse: true,
  weather: {
    country: Countries.default,
    city: 'Deventer',
    temperature: Temperature.Celsius,
    position: Position.Bottom,
  },
}

const settingsReducer = (state = initialState, action: AnyAction): SettingsStateI => {
  const { type, payload } = action

  switch (type) {
    case SET_DATE_FORMAT:
      return {
        ...state,
        dateFormat: payload,
      }
    case SET_TIME_FORMAT:
      return {
        ...state,
        timeFormat: payload,
      }
    case TOGGLE_SHOW_DAYPART:
      return {
        ...state,
        clock: {
          ...state.clock,
          showDayPart: payload,
        },
      }
    case TOGGLE_SHOW_SECONDS:
      return {
        ...state,
        clock: {
          ...state.clock,
          showSeconds: payload,
        },
      }
    case TOGGLE_SHOW_MINUTES:
      return {
        ...state,
        clock: {
          ...state.clock,
          showMinutes: payload,
        },
      }
    case SET_VOLUME:
      return {
        ...state,
        volume: payload,
      }

    case TOGGLE_SHOW_MENU:
      return {
        ...state,
        showMenu: payload,
      }
    case TOGGLE_SLEEP_MODUS:
      return {
        ...state,
        sleepModus: {
          ...state.sleepModus,
          enabled: payload,
        },
      }
    case SET_SLEEP_START:
      return {
        ...state,
        sleepModus: {
          ...state.sleepModus,
          start: payload,
        },
      }
    case SET_SLEEP_END:
      return {
        ...state,
        sleepModus: {
          ...state.sleepModus,
          end: payload,
        },
      }
    case SET_LANGUAGE:
      return {
        ...state,
        language: payload,
      }
    case SET_THEME:
      return {
        ...state,
        theme: payload,
      }
    case SET_DAYPART_START: {
      // set the start time of the dayPart to the selected time
      // set the end time of the pervious dayPart to the selected time

      const dayPartKeys = getDayPartsForLanguage(state.language)

      const currentDayPartIndex = dayPartKeys.indexOf(payload.key)
      let previousDayPartIndex = currentDayPartIndex - 1
      if (previousDayPartIndex < 0) {
        previousDayPartIndex = dayPartKeys.length - 1
      }

      const newState = produce(state, (nextState) => {
        nextState.dayParts[payload.key].start = payload.time
        nextState.dayParts[dayPartKeys[previousDayPartIndex]].end = payload.time
      })

      return newState
    }

    case SET_FIRST_TIME_USE:
      return {
        ...state,
        firstTimeUse: payload,
      }
    case SET_WEATHER_COUNTRY:
      return produce(state, (nextState) => {
        nextState.weather.country = payload
      })
    case SET_WEATHER_CITY:
      return produce(state, (nextState) => {
        nextState.weather.city = payload
      })
    case SET_WEATHER_TEMPERATURE:
      return produce(state, (nextState) => {
        nextState.weather.temperature = payload
      })
    case SET_WEATHER_POSITION:
      return produce(state, (nextState) => {
        nextState.weather.position = payload
      })
    case SET_CLOCK_TYPE:
      return produce(state, (nextState) => {
        nextState.clock.type = payload
      })
    case SET_COLOR:
      return produce(state, (nextState) => {
        nextState.colors.textColor = payload
      })
    case SET_BACKGROUND:
      return produce(state, (nextState) => {
        nextState.colors.backgroundColor = payload
      })
    case SET_INITIAL_SETTTING:
      return {
        ...state,
        ...payload,
      }

    default:
      return state
  }
}

export default settingsReducer

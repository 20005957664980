import React, { useEffect } from 'react'
import WeekDay from './WeekDay'
import DayViewHeader from '../day/DayViewHeader'
import './WeekView.css'
import addDays from 'date-fns/addDays'
import moment from 'moment'
import useActivities from '../../hooks/activities/useActivities'
import { Activity } from '../../store/activities/types'
import useAnalytics from '../../hooks/analytics/useAnalytics'

const WeekView: React.FC = () => {
  const activities = useActivities()
  const { trackView } = useAnalytics()

  useEffect(() => {
    trackView('Week')
  })

  return (
    <div className="bg-blue-200 w-full h-full p-2 pt-10">
      <DayViewHeader />
      <div className="flex mt-5 ml-5 mr-5 weekview border-aqua border-2 rounded-md">
        {displayDays(activities.activities)}
      </div>
    </div>
  )
}

const displayDays = (activities: Activity[]) => {
  const data: Array<Date> = []
  const startOfWeek = moment().startOf('week').toDate()
  ;[...Array(7)].map((x, i) => data.push(addDays(startOfWeek, i)))
  const days = data.map((d: Date) => <WeekDay key={d.toDateString()} date={d} activities={activities} />)
  return <>{days}</>
}

export default WeekView

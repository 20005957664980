import React from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'

import './SetupButton.css'

export interface SetupButtonProps extends React.HTMLProps<HTMLButtonElement> {
  disabled?: boolean
  to?: string
  isBack?: boolean
  showAfter?: boolean
  className?: string
}

const SetupButton: React.FC<SetupButtonProps> = (props: SetupButtonProps) => {
  const { className, children, disabled, isBack, showAfter, to, onClick } = props

  if (disabled === true) {
    return (
      <button
        className={clsx('btn btn-blue', className, {
          'btn-blue--outline': isBack,
          'btn-blue--disabled': disabled,
          'btn-blue--after': showAfter,
        })}
      >
        {children}
      </button>
    )
  }

  if (onClick) {
    return (
      <button
        className={clsx('btn btn-blue', className, {
          'btn-blue--outline': isBack,
          'btn-blue--disabled': disabled,
          'btn-blue--after': showAfter,
        })}
        onClick={onClick}
      >
        {children}
      </button>
    )
  }

  return (
    <Link to={to}>
      <button
        className={clsx('btn btn-blue', className, {
          'btn-blue--outline': isBack,
          'btn-blue--disabled': disabled,
          'btn-blue--after': showAfter,
        })}
      >
        {children}
      </button>
    </Link>
  )
}

SetupButton.defaultProps = {
  disabled: false,
  isBack: false,
  showAfter: true,
}

export default SetupButton

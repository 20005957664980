import React from 'react'
import Moment from 'react-moment'
import useClock from '../../hooks/settings/useClock'
import useColor from '../../hooks/settings/useColor'
import i18n from '../../i18n'
import { Color } from '../../store/settings/types'
import useDateInterval from '../../hooks/interval/useDateInterval'
import DayPart from '../daypart/DayPart'

const SmallDayComponent: React.FC = () => {
  const { color: textColor } = { ...useColor(Color.Text) }
  const { dateFormat, showDayPart } = useClock()
  const dateInterval = useDateInterval(60)

  return (
    <div
      className="absolute text-center text-4xl px-5 bottom-0 left-0 w-full h-20 flex items-center justify-center"
      style={{ color: textColor }}
    >
      <div>
        <Moment locale={i18n.language} format="dddd">
          {dateInterval.date}
        </Moment>{' '}
        {showDayPart && <DayPart />}{' '}
        <Moment locale={i18n.language} format={dateFormat}>
          {dateInterval.date}
        </Moment>
      </div>
    </div>
  )
}

export default SmallDayComponent

import { HandInterface, Hands } from './Hand'

export function calculateHandAngle(args: HandInterface): number {
  const { type, hours, minutes, seconds } = args
  let handAngle = 0

  switch (type) {
    case Hands.Second:
      handAngle = seconds * 6
      break
    case Hands.Minute:
      handAngle = minutes * 6 + (6 / 60) * seconds
      break
    case Hands.Hour:
      handAngle = hours * 30 + (30 / 60) * minutes + (30 / 3600) * seconds
      break
    default:
      handAngle = 360
  }

  return handAngle
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useRef } from 'react'
import { off, on } from '../../core/helper/utils'

interface Options {
  isPreventDefault?: boolean
  delay?: number
}

interface LongPressReturn {
  onMouseDown: (event: any) => void
  onTouchStart: (event: any) => void
  onMouseUp: () => void
  onMouseLeave: () => void
  onTouchEnd: () => void
}

const isTouchEvent = (event: Event): event is TouchEvent => {
  return 'touches' in event
}

const preventDefault = (event: Event) => {
  if (!isTouchEvent(event)) return

  if (event.touches.length < 2 && event.preventDefault) {
    event.preventDefault()
  }
}

const useLongPress = (
  callback: (e: TouchEvent | MouseEvent) => void,
  { isPreventDefault = true, delay = 300 }: Options = {},
): LongPressReturn => {
  const timeout = useRef<ReturnType<typeof setTimeout>>()
  const target = useRef<EventTarget>()

  const start = useCallback(
    (event: TouchEvent | MouseEvent) => {
      // prevent ghost click on mobile devices
      if (isPreventDefault && event.target) {
        on(event.target, 'touchend', preventDefault, { passive: false })
        target.current = event.target
      }
      timeout.current = setTimeout(() => callback(event), delay)
    },
    [callback, delay, isPreventDefault],
  )

  const clear = useCallback(() => {
    // clearTimeout and removeEventListener
    timeout.current && clearTimeout(timeout.current)

    if (isPreventDefault && target.current) {
      off(target.current, 'touchend', preventDefault)
    }
  }, [isPreventDefault])

  return {
    onMouseDown: (event: any) => start(event),
    onTouchStart: (event: any) => start(event),
    onMouseUp: clear,
    onMouseLeave: clear,
    onTouchEnd: clear,
  } as const
}

export default useLongPress

import { Capacitor } from '@capacitor/core'
import { NativeAudio } from '@capacitor-community/native-audio'

interface AudioInterface {
  interval: number
  repeat: number
  volume: number
  audioName: string
  enable: boolean
}

const useAudio = (settings: AudioInterface): void => {
  const { interval, repeat, volume, audioName, enable } = settings
  if (Capacitor.isNativePlatform() && enable) {
    NativeAudio.preload({
      assetId: 'audio',
      assetPath: `../../assets/audio/${audioName}.mp3`,
      audioChannelNum: 1,
      isUrl: false,
    })

    NativeAudio.setVolume({ assetId: 'audio', volume: volume })

    let x = 0
    const intervalID = setInterval(() => {
      NativeAudio.play({
        assetId: 'audio',
        time: 0,
      })
      if (++x === repeat) {
        window.clearInterval(intervalID)
        NativeAudio.unload({ assetId: 'audio' })
      }
    }, interval * 1000)
  }
}

export default useAudio

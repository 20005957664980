import React from 'react'
import PropTypes from 'prop-types'
import { IonItem, IonLabel, IonList, IonListHeader, IonRange } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { SettingsVolume as SettingsVolumeInterface } from '../../../core/interfaces/settings'
import { NativeAudio } from '@capacitor-community/native-audio'
import { Capacitor } from '@capacitor/core'

const SettingsVolume: React.FC<SettingsVolumeInterface> = ({ handleChange, volume }) => {
  const { t } = useTranslation()
  return (
    <>
      <IonListHeader>
        <IonLabel className="text-xl dc7:text-lg font-bold">{t('settings.volume.title')}</IonLabel>
      </IonListHeader>
      <IonList lines="full">
        <IonItem className="dc7:text-sm">
          <IonLabel>{t('settings.volume.set')}</IonLabel>
          <IonRange
            pin={true}
            value={volume}
            onIonChange={(e) => {
              playSound(e.detail.value as number)
              handleChange(e.detail.value as number)
            }}
          />
        </IonItem>
      </IonList>
    </>
  )
}

SettingsVolume.propTypes = {
  volume: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
}

function playSound(volume: number) {
  if (Capacitor.isNativePlatform()) {
    NativeAudio.preload({
      assetId: 'fire',
      assetPath: 'fire.mp3',
      audioChannelNum: 1,
      isUrl: false,
    })
    NativeAudio.setVolume({
      assetId: 'fire',
      volume: volume / 100,
    })
  }
}

export default SettingsVolume

import { useDispatch, useSelector } from 'react-redux'
import { updateDayPartStart } from '../../store/settings/actions/daypart-actions'
import { DayPartKeys, SettingsStateI } from '../../store/settings/types'
import { RootState } from '../../store/store'

export interface DayPartsHook {
  key: DayPartKeys
  start: string
  end: string
  setDayPartStart: (time: string) => void
}

const useDayParts = (dayPartKey: DayPartKeys): DayPartsHook => {
  const { dayParts } = useSelector((state: RootState) => state.settings) as SettingsStateI

  const currentDayPart = dayParts[dayPartKey]
  const dispatch = useDispatch()

  const setDayPartStart = (time: string): void => {
    dispatch(updateDayPartStart(dayPartKey, time))
  }

  return {
    key: dayPartKey,
    start: currentDayPart.start,
    end: currentDayPart.end,
    setDayPartStart,
  }
}

export default useDayParts

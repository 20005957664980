import React from 'react'
import { TimeInterface } from './useTime'
import Center from './Center'
import Hand, { Hands } from './Hand'
import './PureAnalogClock.css'
import Notch, { NotchType } from './Notch'
import useClock from '../../../hooks/settings/useClock'

export interface PureAnalogClockProps {
  size: number
  time: TimeInterface
}

export const PureAnalogClock: React.FC<PureAnalogClockProps> = (props: PureAnalogClockProps) => {
  const { size, time } = props
  const { showMinutes, showSeconds } = useClock()

  const clockSize = {
    width: `${size / 16}rem`,
    height: `${size / 16}rem`,
  }

  const minuteNotches = []
  for (let angle = 0; angle < 360; angle += 6) {
    minuteNotches.push(<Notch type={NotchType.Small} notchAngle={angle} key={`minute-notch-${angle}`} />)
  }

  const hourNotches = []
  for (let hour = 1; hour <= 12; hour++) {
    const angle = 30 * hour
    hourNotches.push(<Notch type={NotchType.Large} notchAngle={angle} hour={hour} key={`hour-notch-${hour}`} />)
  }

  const handSeconds = showSeconds ? <Hand type={Hands.Second} {...time} /> : null
  const handMinutes = showMinutes ? <Hand type={Hands.Minute} {...time} /> : null
  return (
    <>
      {/* <div className="text-center text-5xl text-white">10:10</div> */}
      <div className="clock" style={clockSize}>
        <Center />
        {minuteNotches}
        {hourNotches}
        {handSeconds}
        {handMinutes}
        <Hand type={Hands.Hour} {...time} />
      </div>
    </>
  )
}

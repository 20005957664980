import { useDispatch, useSelector } from 'react-redux'
import { SettingsClock } from '../../core/interfaces/settings'
import {
  toggleDayPartSetting,
  toggleMinutesSetting,
  toggleSecondsSetting,
  setDateSetting,
  setTimeSetting,
} from '../../store/settings/actions'
import { SettingsStateI } from '../../store/settings/types'
import { RootState } from '../../store/store'

const useClock = (): SettingsClock => {
  const dispatch = useDispatch()
  const { dateFormat, timeFormat, clock } = useSelector((state: RootState) => state.settings) as SettingsStateI

  const { showDayPart, showSeconds, showMinutes } = clock

  const toggleDayPart = (isChecked: boolean): void => {
    dispatch(toggleDayPartSetting(isChecked))
  }

  const toggleMinutes = (isChecked: boolean): void => {
    dispatch(toggleMinutesSetting(isChecked))
  }

  const toggleSeconds = (isChecked: boolean): void => {
    dispatch(toggleSecondsSetting(isChecked))
  }

  const setDateFormat = (date: string): void => {
    dispatch(setDateSetting(date))
  }

  const setTimeFormat = (time: string): void => {
    dispatch(setTimeSetting(time))
  }

  return {
    dateFormat,
    timeFormat,
    showDayPart,
    showSeconds,
    showMinutes,
    toggleDayPart,
    toggleMinutes,
    toggleSeconds,
    setDateFormat,
    setTimeFormat,
  }
}

export default useClock

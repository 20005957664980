import React, { useLayoutEffect, useRef } from 'react'
import './VideoTrack.css'

interface Props {
  track: any //eslint-disable-line
}

const VideoTrack = ({ track }: Props) => {
  const trackRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (track !== null) {
      const child = track.attach()
      trackRef.current?.classList.add(track.kind)
      trackRef.current?.appendChild(child)
    }
  }, []) //eslint-disable-line

  return <div className="track" ref={trackRef}></div>
}

export default VideoTrack

import { useDispatch, useSelector } from 'react-redux'
import {
  setWeatherCity,
  setWeatherCountry,
  setWeatherPosition,
  setWeatherTemperature,
} from '../../store/settings/actions'
import { Weather } from '../../store/settings/types'
import { RootState } from '../../store/store'

export interface WeatherInterface extends Weather {
  setCountry: (country: string) => void
  setCity: (city: string) => void
  setTemperature: (temperature: string) => void
  setPosition: (position: string) => void
}

const useWeather = (): WeatherInterface => {
  const dispatch = useDispatch()

  const { country, city, temperature, position } = useSelector((state: RootState) => state.settings.weather)

  const setCountry = (value: string) => {
    dispatch(setWeatherCountry(value))
  }

  const setCity = (value: string) => {
    dispatch(setWeatherCity(value))
  }

  const setTemperature = (value: string) => {
    dispatch(setWeatherTemperature(value))
  }

  const setPosition = (value: string) => {
    dispatch(setWeatherPosition(value))
  }

  return {
    country,
    city,
    temperature,
    position,
    setCountry,
    setCity,
    setTemperature,
    setPosition,
  }
}

export default useWeather

import React from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { Language } from '../../../store/settings/types'
import useLanguage from '../../../hooks/settings/useLanguage'

import helpNL from './help-pages/help_nl'
import helpDE from './help-pages/help_de'
import helpEN from './help-pages/help_en'
import helpFR from './help-pages/help_fr'
import helpES from './help-pages/help_es'
import helpDA from './help-pages/help_da'

const getHelpText = (language: string): string => {
  let helpText = ''

  switch (language) {
    case Language.dutch: {
      helpText = helpNL
      break
    }
    case Language.german: {
      helpText = helpDE
      break
    }
    case Language.english: {
      helpText = helpEN
      break
    }
    case Language.french: {
      helpText = helpFR
      break
    }
    case Language.spanish: {
      helpText = helpES
      break
    }
    case Language.danish: {
      helpText = helpDA
      break
    }
  }

  return helpText
}

const Help: React.FC = () => {
  const { language } = useLanguage()

  const markdown = `A paragraph with *emphasis* and **strong importance**.

> A block quote with ~strikethrough~ and a URL: https://reactjs.org.

# Syntax 
## Lists
* [ ] todo
* [x] done

A table:

| a | b |
| - | - |
`

  return (
    <>
      <ReactMarkdown remarkPlugins={[remarkGfm]}>{markdown}</ReactMarkdown>,
      <div dangerouslySetInnerHTML={{ __html: getHelpText(language) }} />
    </>
  )
}

export default Help

import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isInBetween } from '../../core/helper/date.helper'
import { getWeather } from '../../store/settings/weatherSlice'
import { RootState } from '../../store/store'
import moment from 'moment'

const INTERVAL = 2 * 60 * 60 * 1000

const useUpdateWeatherForecast = (): void => {
  const dispatch = useDispatch()

  const { country, city, temperature } = useSelector((state: RootState) => state.settings.weather)
  const { language } = useSelector((state: RootState) => state.settings)

  useEffect(() => {
    dispatch(getWeather({ country, city, unit: temperature, language }))
    setInterval(() => {
      const current = moment().format('DDMMYYYY HH:mi')
      if (
        isInBetween(current, '08:00', '08:10') ||
        isInBetween(current, '10:00', '10:10') ||
        isInBetween(current, '12:00', '12:10') ||
        isInBetween(current, '14:00', '14:10') ||
        isInBetween(current, '16:00', '16:10') ||
        isInBetween(current, '18:00', '18:10')
      ) {
        dispatch(getWeather({ country, city, unit: temperature, language }))
      }
    }, INTERVAL)
  }, [country, city, temperature, language, dispatch])
}

export default useUpdateWeatherForecast

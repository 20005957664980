import React from 'react'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import moment from 'moment'

export interface PureWeekDayHeaderInterface {
  date: Date
}

export const PureWeekDayHeader: React.FC<PureWeekDayHeaderInterface> = (props: PureWeekDayHeaderInterface) => {
  const { date } = props
  const { i18n } = useTranslation()
  const today = moment().format('YYYY-DD-MM') === moment(date).format('YYYY-DD-MM')
  const borderColor = today ? 'border-blue-500' : 'border-gray'

  return (
    <>
      <div className="text-center uppercase border-gray p-2">
        <div className="text-xl dc7:text-lg font-bold pt-5">
          <Moment format="dddd" locale={i18n.language}>
            {date}
          </Moment>
        </div>
        <div className={`${borderColor} border-b-2 pb-5`}>
          <Moment format="DD-MM">{date}</Moment>
        </div>
      </div>
    </>
  )
}

const WeekDayHeader: React.FC<PureWeekDayHeaderInterface> = (props: PureWeekDayHeaderInterface) => {
  const { date } = props
  return <PureWeekDayHeader date={date}></PureWeekDayHeader>
}

export default WeekDayHeader

import { IonContent, IonPage } from '@ionic/react'
import React from 'react'
import SettingsButton from '../components/home/SettingsButton'
import ClockFace from '../components/home/ClockFace'
import useColor from '../hooks/settings/useColor'
import { ClockTypeEnum, Color } from '../store/settings/types'
import useClockType from '../hooks/settings/clockType'
import DayView from '../components/day/DayView'
import WeekView from '../components/week/WeekView'
import useSleepModus from '../hooks/settings/useSleepModus'
import * as firebase from 'firebase/app'
import { getDatabase } from 'firebase/database'
import { firebaseConfig } from '../core/const'
import useUpdateActivities from '../hooks/activities/useUpdateActivities'
import { useUpdatesFirebase } from '../hooks/firebase/useUpdatesFirebase'

const Home: React.FC = () => {
  const { color } = useColor(Color.Background)
  const { type } = useClockType()
  const { isSleeping } = useSleepModus()

  const fbApp = firebase.initializeApp(firebaseConfig)
  const fbDatabase = getDatabase(fbApp)
  const result = useUpdatesFirebase(fbDatabase)
  useUpdateActivities(result)

  const defaultView = (
    <div className="h-full overflow-hidden" style={{ background: color }}>
      <SettingsButton />
      <ClockFace />
    </div>
  )

  const dayView = (
    <div className="h-full overflow-hidden" style={{ background: color }}>
      <SettingsButton />
      <DayView />
    </div>
  )

  const weekView = (
    <div className="h-full overflow-hidden" style={{ background: color }}>
      <SettingsButton />
      <WeekView />
    </div>
  )

  let clockView = defaultView

  switch (type) {
    case ClockTypeEnum.Day: {
      clockView = dayView
      break
    }
    case ClockTypeEnum.Week: {
      clockView = weekView
      break
    }
  }

  if (isSleeping) {
    clockView = (
      <div className="bg-black h-full">
        <SettingsButton />
      </div>
    )
  }

  return (
    <IonPage>
      <SettingsButton />
      <IonContent fullscreen>{clockView}</IonContent>
    </IonPage>
  )
}

export default Home

import React from 'react'
import Moment from 'react-moment'
import useClock from '../../hooks/settings/useClock'
import useColor from '../../hooks/settings/useColor'
import i18n from '../../i18n'
import { Color } from '../../store/settings/types'
import useDateInterval from '../../hooks/interval/useDateInterval'
import DayPart from '../daypart/DayPart'

export const DayComponent: React.FC = () => {
  const { color: textColor } = { ...useColor(Color.Text) }
  const { dateFormat, showDayPart } = useClock()
  const dateInterval = useDateInterval(60)

  return (
    <div className="flex-1 p-5">
      <div className="flex flex-col justify-center text-center">
        <div style={{ color: textColor, fontSize: '7rem' }} className="font-bold leading-snug py-5">
          <Moment locale={i18n.language} format="dddd">
            {dateInterval.date}
          </Moment>
        </div>
        {showDayPart && (
          <div style={{ color: textColor }} className="text-8xl font-bold leading-snug py-5">
            <DayPart />
          </div>
        )}
        <div style={{ color: textColor }} className="text-7xl font-bold leading-snug py-5">
          <Moment locale={i18n.language} format={dateFormat}>
            {dateInterval.date}
          </Moment>
        </div>
      </div>
    </div>
  )
}

import { IonCol, IonContent, IonGrid, IonPage, IonRow } from '@ionic/react'
import React from 'react'

const Goodmorning: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen>
        <IonGrid className="bg-black h-full">
          <IonRow>
            <IonCol></IonCol>
            <IonCol className="ion-text-center">
              <div className="container">
                <div className="mt-60"></div>
                <img className="mx-auto" src="/assets/icon/116.png" alt="weather" />
                <h1 className="mt-20 text-8xl text-white">Goedemorgen</h1>
                <h1 className="mt-20 text-6xl text-white">Moeder</h1>
              </div>
            </IonCol>
            <IonCol></IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
}

export default Goodmorning

import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import useLongPress from '../../hooks/long-press/useLongPress'

interface Props {
  onClick?: () => void
}

const SettingsButton: React.FC<Props> = (props: Props) => {
  const [inLongPress, setInLongPress] = useState(false)
  const history = useHistory()
  const timer = useRef(null)
  const { t } = useTranslation()

  useEffect(() => {
    return () => {
      clearInterval(timer.current)
    }
  }, [])

  const hideButton = () => {
    setInLongPress(false)
    clearInterval(timer.current)
  }

  const updateTimer = () => {
    clearInterval(timer.current)
    timer.current = setInterval(() => hideButton(), 2000)
  }

  const handleClick = () => {
    if (inLongPress) {
      if (props.onClick) {
        props.onClick()
      }
      hideButton()
      history.push('/settings')
    }
  }

  const onLongPress = () => {
    setInLongPress(true)
    updateTimer()
  }

  const longPressEvent = useLongPress(onLongPress, {
    isPreventDefault: false,
    delay: 3000,
  })

  return (
    <div
      className="absolute top-0 left-0 right-0 bottom-0 z-10 flex flex-col justify-center"
      {...longPressEvent}
      onClick={() => handleClick()}
    >
      {inLongPress && (
        <div className="rounded-lg w-50 h-30 text-3xl text-white font-bold m-3 p-5 text-center bg-green-400">
          {t('Settings')}
        </div>
      )}
    </div>
  )
}

export default SettingsButton
